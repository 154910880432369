import React from "react";
import { StyledFooter } from "./styles";

function Footer() {
  return (
    <StyledFooter>
      <span>© 2023 DWield.ai. All Rights Reserved.</span>
    </StyledFooter>
  );
}

export default Footer;
