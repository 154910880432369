import React from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";

function LinearProgressWithLabel(props) {
    return (
      <Box >
        <Box sx={{ minWidth: 35 }}>
          <Typography
            variant="body2"
            color="text.secondary"
          >{`${props.value}%`}</Typography>
        </Box>
        <br/>
        <Box >
          <LinearProgress variant="determinate" {...props} />
        </Box>
      </Box>
    );
  }

export default LinearProgressWithLabel