import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompanyName: (state, action) => {
      state.name = action.payload;
    },
  },
});

export const { setCompanyName } = companySlice.actions;

export default companySlice.reducer;
