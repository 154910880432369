import React from "react";
import { StyledSVGwrapper } from "./styles";

function SVGLoader({ icon: Child, color }) {
  return (
    <StyledSVGwrapper color={color}>
      <Child />
    </StyledSVGwrapper>
  );
}

export default SVGLoader;
