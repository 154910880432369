import React from "react";
import { StyledInputWrapper } from "./styles";
function DataList(props) {
  const {
    label,
    name,
    onChange,
    options,
    value,
    listid,
    validation,
    errorMessage,
  } = props;
  return (
    <StyledInputWrapper>
      <label>{label}</label>
      <input
        name={name}
        value={value}
        onChange={onChange}
        list={listid}
        {...validation}
        className={errorMessage && "with-error"}
      />
      <datalist id={listid}>
        {options?.length > 0 &&
          options.map((i, index) => {
            return (
              <option key={i} value={i}>
                {i}
              </option>
            );
          })}
      </datalist>
      {errorMessage && <span className="error-msg">{errorMessage}</span>}
    </StyledInputWrapper>
  );
}

export default DataList;
