import React, { useEffect, useState } from "react";
import {
  StyledPaper,
  StyledFormSection,
  StyledApiKeyContainer,
} from "./styles";
import DropdownField from "../../components/form-elements/drop-down";
import { Button } from "@mui/material";
import useApi from "../../hooks/useApi";
import { fetchKey, generateApiKey } from "../../services";
import { useForm } from "react-hook-form";
import { toastInstance } from "../../utils/toastInstance";
import { sdkTypes } from "../../constants/genericConstants";
import Spinner from "../../components/spinner";
import { formatTimeStamp } from "../../utils/commonUtils";

function IntegrationConsole() {
  const [makeApiCall, loading] = useApi();
  const [apiKeys, setApiKeys] = useState(null);
  const form = useForm();
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;

  const onSubmit = async (data) => {
    console.log(data);
    const res = await makeApiCall(generateApiKey, { ...data });
    if (res?.status === 201) {
      toastInstance("success", "API key generated successfully!");
    }
    fetchData();
  };

  const fetchData = async () => {
    const res = await makeApiCall(fetchKey);
    if (res?.status === 200) {
      setApiKeys(res.data.tokenSDKS[0]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText(apiKeys.token)
      toastInstance('success', "Copied!")
    } catch (error) {
      console.log(error)
      toastInstance('error', "Copy to clipboard is not supported in your browser!")
    }
  }

  return (
    <>
      {loading && <Spinner />}
      <StyledPaper>
        <h1>Integration console</h1>
        <section>
          <h4>API Keys</h4>
          <br />
          {!apiKeys ? (
            <>
              <p>
                You currently do not have any API keys. To create an API key,
                please select an SDK from the dropdown menu below. Note that
                once the key is generated, the selected SDK type cannot be
                changed. Make sure to choose the SDK that best suits your
                integration needs. Keep your API key secure and do not share it
                with others or expose it in browser or client-side code.
              </p>
              <br />
              <StyledFormSection>
                <DropdownField
                  options={sdkTypes}
                  name="sdkName"
                  id="sdkName"
                  label="Select your SDK"
                  validation={register("sdkName", {
                    required: "SDK type is required",
                  })}
                  errorMessage={errors.sdkName?.message}
                />
                <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                  Generate API Key
                </Button>
              </StyledFormSection>
            </>
          ) : (
            <>
              <p>
                Your secret API keys are listed below. Keep your API key secure
                and do not share it with others or expose it in browser or
                client-side code.
              </p>
              <br />
              <StyledApiKeyContainer>
                <h3>{apiKeys.token}</h3>
                <section className="token-details">
                  <section>
                    <p>SDK Type : {apiKeys.sdkName}</p>
                    <p>Generated at : {formatTimeStamp(apiKeys.createdOn)}</p>
                    <p>Generated by : {apiKeys.createdBy}</p>
                  </section>
                  <button onClick={copyToClipboard}>Copy to clipboard</button>
                </section>
              </StyledApiKeyContainer>
            </>
          )}
        </section>
      </StyledPaper>
    </>
  );
}

export default IntegrationConsole;
