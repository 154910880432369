import React, { useState, useMemo, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableToolbar from "../../components/data-table/TableToolbar";
import TableHead from "../../components/data-table/TableHeader";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { StyledTableActionCell } from "../../common-styles";
import { tableHeaders } from "../../constants/tableConfig";
import {
  getComparator,
  stableSort,
} from "../../components/data-table/helper-functions";
import AddNewGroupButton from "../add-new-group";
import Switch from "@mui/material/Switch";
import ViewmoreToolTip from "../view-more-tooltip";
import { deleteGroup, getGroups, searchGroups, updateGroup } from "../../services";
import { toastInstance } from "../../utils/toastInstance";
import useApi from "../../hooks/useApi";
import { useSelector, useDispatch } from "react-redux";
import AddorEditGroup from "../dialog/AddorEditGroup";
import Spinner from "../spinner";
import Confirmation from "../dialog/Confirmation";
import Pagination from "../data-table/Pagination";
import BulkUploadBtn from "../bulk-uploader-button";
import { removeSearchCriteria } from "../../redux/features/searchSlice";

const headCells = tableHeaders["GROUPS"];

function Groups() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0)
  const [makeApiCall, loading] = useApi();
  const { userDetails } = useSelector((state) => state?.user?.userInfo) || {};
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showEditGroup, setShowEditGroup] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const { searchCriteria } = useSelector((state) => state.search);
  const dispatch = useDispatch();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const visibleRows = useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, rows]
  );
  const formatFieldValues = (data) => {
    const dataList = data.split(",")
    if(dataList.length>2){
      return <ViewmoreToolTip fieldValues={dataList}/>
    }else{
      return data
    }
  }
  const handleCaseSwitch = async (e, groupId) => {
    const res = await makeApiCall(updateGroup, groupId, {
      status: e.target.checked ? "Active" : "Inactive",
    });
    if (res?.status === 200) {
      fetchGroups();
      toastInstance("success", "Group updated successfully!");
    }
  };

  const fetchGroups = async (payload = null, limit = 5, offset = 0) => {
    const res = await makeApiCall(getGroups, limit, offset);
    if (res) {
      const { groups, totalRecords } = res?.data;
      setRows(groups);
      setTotalRecords(totalRecords)
    } else {
      toastInstance("error", "Something went wrong!");
    }
  };

  const searchGroupData = async (
    searchPayload,
    pageSize = 5,
    startIndex = 0
  ) => {
    const payload = searchPayload
      ? { ...searchPayload, pageSize, startIndex }
      : {
          ...searchCriteria,
          pageSize,
          startIndex,
        };
    const res = await makeApiCall(searchGroups, payload);
    if (res) {
      const { groups, totalRecords } = res?.data;
      setRows(groups);
      setTotalRecords(totalRecords)
    }
  };

  useEffect(() => {
    fetchGroups();
    return () => {
      dispatch(removeSearchCriteria());
    };
  }, []);

  const handleUpdate = (group) => {
    setSelectedGroup(group);
    setShowEditGroup(true);
  };

  const handleDelete = async (group) => {
    setSelectedGroup(group);
    setShowConfirm(true);
  };

  const confirmDelete = async () => {
    const res = await makeApiCall(deleteGroup, selectedGroup?.groupId);
    if (res.status === 200) {
      toastInstance("success", "Group successfully deleted!");
      setShowConfirm(false);
      fetchGroups();
    }
  };

  return (
    <section>
      <Box sx={{ width: "100%" }}>
        {showConfirm && (
          <Confirmation
            handleClose={() => setShowConfirm(false)}
            handleConfirm={confirmDelete}
          />
        )}
        {loading && <Spinner />}
        {showEditGroup && (
          <AddorEditGroup
            handleClose={() => setShowEditGroup(false)}
            updateList={fetchGroups}
            groupData={selectedGroup}
            isEdit
          />
        )}
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableToolbar
            title={"Groups"}
            component={"GROUPS"}
            fetchData={searchGroupData}
            fetchAllData={fetchGroups}
            compoentToRender={
              userDetails?.role.includes("Admin") && (
                <>
                  <AddNewGroupButton updateList={fetchGroups} />
                  <BulkUploadBtn parentComponent={"Groups"} />
                </>
              )
            }
          />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="Risk Configuration">
              <TableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length}
                headCells={headCells}
              />
              <TableBody>
                {visibleRows.length> 0 && visibleRows.map((row) => (
                  <TableRow hover key={row.groupId} sx={{ cursor: "pointer" }}>
                    <TableCell align="left">{row.fieldType}</TableCell>
                    <TableCell align="left">
                      {formatFieldValues(row.fieldValue)}
                    </TableCell>
                    <TableCell align="left">{row.groupName}</TableCell>
                    <TableCell align="left">
                      <Switch
                        checked={row.status === "Active"}
                        onChange={(e) => handleCaseSwitch(e, row.groupId)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <StyledTableActionCell>
                        {(userDetails?.role.includes("Admin") ||
                          userDetails?.role === "Editor") && (
                          <BorderColorOutlinedIcon
                            sx={{ color: "#3965FF" }}
                            onClick={() => handleUpdate(row)}
                          />
                        )}
                        {userDetails?.role.includes("Admin") && (
                          <DeleteOutlinedIcon
                            sx={{ color: "#BD041A" }}
                            onClick={() => handleDelete(row)}
                          />
                        )}
                      </StyledTableActionCell>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalRecords}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            fetchData={fetchGroups}
            searchData={searchGroupData}
          />
        </Paper>
      </Box>
    </section>
  );
}

export default Groups;
