import React from "react";
import { StyledInputWrapper } from "./styles";

function RangeInput(props) {
    const {label, name, value, onChange} = props
  return (
    <StyledInputWrapper>
      <label >{label}</label>
      <input name={name} type="range" min="0" max="100" onChange={onChange} value={value || 0}/>
      <label >{value}</label>
    </StyledInputWrapper>
  );
}

export default RangeInput;
