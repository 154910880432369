import React, { useEffect } from "react";
import Sidebar from "../components/sidebar";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import {
  StyledLayoutContainer,
  StyledLayoutContentWrapper,
  StyledOutletWrapper,
} from "./styles";
import { useSelector, useDispatch } from "react-redux";
import useApi from "../hooks/useApi";
import { getUserInfo } from "../services";
import { setUser } from "../redux/features/userSlice";
import Spinner from "../components/spinner";
import { formatTimeStamp } from "../utils/commonUtils";
import routesConfig from "../constants/routeConfig";
import { setCompanyName } from "../redux/features/companySlice";

function MainLayout() {
  const { userDetails } = useSelector((state) => state?.user?.userInfo) || {};
  const [makeApiCall, loading] = useApi();
  const dispatch = useDispatch();
  const location = useLocation();
  const showSidebar =
    location.pathname === routesConfig.SUPER_ADMIN_CONSOLE ? false : true;

  const fetchUserDetails = async () => {
    const res = await makeApiCall(getUserInfo);
    if (res) {
      const userData = {
        jwToken: localStorage.getItem("authToken"),
        userName: res.data.userName,
        userDetails: {
          role: res.data.role,
          lastLogin: formatTimeStamp(res.data.lastLogin),
        },
      };
      dispatch(setUser(userData));
      if(res.data.role === "SuperAdmin"){
        dispatch(setCompanyName(res.data.organizationName))
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    if (!userDetails?.role) {
      fetchUserDetails();
    }
  }, []);

  return (
    <StyledLayoutContainer>
      {loading && <Spinner />}
      {showSidebar && <Sidebar />}
      <StyledLayoutContentWrapper>
        <Header />
        <StyledOutletWrapper>
          {userDetails?.role ? <Outlet /> : null}
        </StyledOutletWrapper>
        <Footer />
      </StyledLayoutContentWrapper>
    </StyledLayoutContainer>
  );
}

export default MainLayout;
