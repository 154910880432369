import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { StyledDialogTitle, StyledTransactionDetailsTable } from "./styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SVGLoader from "../svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import { getTransactionDetails, getTriggeredRules, updateTransaction } from "../../services";
import {
  ADDITIONAL_FIELDS,
  KEY_FIELDS,
} from "../../constants/transactionDetailsConstants";
import useApi from "../../hooks/useApi";
import Spinner from "../spinner";
import { toastInstance } from "../../utils/toastInstance";
import { formatTimeStamp } from "../../utils/commonUtils";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        overflowY: "scroll",
        height: "500px",
        background: "#F4F7FE",
        padding: "18px",
      }}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Confirmation({ payload, handleClose, handleSubmit }) {
  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure? Do you really want to classify this data as{" "}
          {payload.classification}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} autoFocus>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function TransactionDetails(props) {
  const { handleClose, transactionId } = props;
  const [value, setValue] = useState(0);
  const [transactionData, setTransactionData] = useState();
  const [triggeredRules, setTriggeredRules] = useState([])
  const [makeApiCall, loading] = useApi();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [classificationData, setClassificationData] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function mapAndMergeFields(fields, data) {
    return fields.map((obj1) => {
      const matchingObj2 = data.find((obj2) => obj2.fieldName === obj1.apiKey);
      const displayName = obj1.fieldName;
      if (matchingObj2) {
        if (obj1.apiKey === "event_timestamp") {
          return {
            ...obj1,
            ...matchingObj2,
            displayName,
            fieldValue: formatTimeStamp(matchingObj2.fieldValue),
          };
        } else {
          return { ...obj1, ...matchingObj2, displayName };
        }
      } else {
        return { obj1, displayName };
      }
    });
  }

  const fetchTransactionDetails = async () => {
    const res = await makeApiCall(getTransactionDetails, transactionId);
    if (res) {
      const transactionKeyData = mapAndMergeFields(KEY_FIELDS, res.data);
      const transactionAddData = mapAndMergeFields(ADDITIONAL_FIELDS, res.data);
      setTransactionData({ transactionKeyData, transactionAddData });
    }
  };

  const fetchTriggeredRulesDetails = async () => {
    const res = await makeApiCall(getTriggeredRules, transactionId);
    if (res) {
      const trigRules = res?.data.filter(i=>i.riskScore != 0)
      setTriggeredRules(trigRules)
    }
  };

  useEffect(() => {
    fetchTransactionDetails();
    fetchTriggeredRulesDetails();
  }, []);

  const handleClassification = (e, data) => {
    const payloadData = {
      listId: data.listId.trim(),
      fieldId: data.fieldName,
      fieldValue: data.fieldValue,
      classification: e.target.value,
    };
    setClassificationData(payloadData);
    setOpenConfirm(true);
  };

  const submitClassification = async () => {
    const res = await makeApiCall(updateTransaction, classificationData);
    if(res?.status === 200){
      toastInstance('success', 'Transaction details updated successfully!')
      fetchTransactionDetails();
      setOpenConfirm(false);
      setClassificationData(null);
    }
  };

  return (
    <>
      {loading && <Spinner />}
      <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="lg">
        {openConfirm && (
          <Confirmation
            payload={classificationData}
            handleClose={() => {
              setOpenConfirm(false);
              setClassificationData(null);
            }}
            handleSubmit={submitClassification}
          />
        )}
        <StyledDialogTitle>
          <section className="dialog-header-content">
            <span>Transaction Details</span>
            <div onClick={handleClose}>
              <SVGLoader icon={iconLibrary.CANCEL_ICON} />
            </div>
          </section>
        </StyledDialogTitle>
        <section>
          {
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Key Details" {...a11yProps(0)} />
                  <Tab label="Additional Details" {...a11yProps(1)} />
                  <Tab label="Triggered Rules" {...a11yProps(2)}/>
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                {transactionData && (
                  <StyledTransactionDetailsTable>
                    <thead>
                      <tr>
                        <th>Variables</th>
                        <th>Values</th>
                        <th>Classification</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionData.transactionKeyData.map((i) => (
                        <tr>
                          <td>{i.displayName}</td>
                          <td>{i.fieldValue}</td>
                          {i.classifiable ? (
                            <td>
                              <select
                                onChange={(e) => handleClassification(e, i)}
                                value={i.classification}
                              >
                                <option>None</option>
                                <option>Blacklisted</option>
                                <option>Whitelisted</option>
                                <option>Semi Blacklisted</option>
                                <option>Semi Whitelisted</option>
                              </select>
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </StyledTransactionDetailsTable>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                {transactionData && (
                  <StyledTransactionDetailsTable>
                    <thead>
                      <tr>
                        <th>Variables</th>
                        <th>Values</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionData.transactionAddData.map((i) => (
                        <tr>
                          <td>{i.displayName}</td>
                          <td>{i.fieldValue}</td>
                        </tr>
                      ))}
                    </tbody>
                  </StyledTransactionDetailsTable>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                {triggeredRules.length > 0 && (
                  <StyledTransactionDetailsTable>
                    <thead>
                      <tr>
                        <th>Rule Name</th>
                        <th>Score</th>
                        <th>Analysis</th>
                      </tr>
                    </thead>
                    <tbody>
                      {triggeredRules.map((i) => (
                        <tr>
                          <td>{i.ruleName}</td>
                          <td>{i.riskScore}</td>
                          <td>{i.ruleAnalysis}</td>
                        </tr>
                      ))}
                    </tbody>
                  </StyledTransactionDetailsTable>
                )}
              </CustomTabPanel>
            </Box>
          }
        </section>
      </Dialog>
    </>
  );
}

export default TransactionDetails;
