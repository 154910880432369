const routesConfig = {
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  TRANSACTIONS: "/transactions",
  USER_MANAGEMENT: "/user-management",
  CUSTOMER_ANALYTICS: "/customer-analytics",
  REPORTS: "/reports",
  RULES: "/risk-rules",
  RULES_CONFIGURATION: "/risk-rules-configuration",
  CONFIGURATIONS: "/configurations",
  DOWNLOADS: "/downloads",
  SUPER_ADMIN_CONSOLE: "/super-admin/console",
  INTEGRATION_CONSOLE: "/integration-console",
};

export default routesConfig;
