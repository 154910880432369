import React from "react";
import { ToastContainer } from 'react-toastify';
import { StyledToast } from "./styles";

function Toast() {
  return (
    <StyledToast>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="colored"
      />
    </StyledToast>
  );
}

export default Toast;
