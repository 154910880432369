import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "../form-elements/text-field";
import {
  StyledDialogTitle,
  StyledDialogActions,
  StyledDialogContent,
} from "./styles";
import SVGLoader from "../svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import { toastInstance } from "../../utils/toastInstance";
import useApi from "../../hooks/useApi";
import { useForm } from "react-hook-form";
import DropdownField from "../form-elements/drop-down";
import {
  companySize,
  industries,
  useCases,
  subscriptionTypes,
} from "../../constants/superAdminConstants";
import {
  addCompany,
  getCompanyDetails,
  updateCompanyDetails,
} from "../../services";
import { useSelector } from "react-redux";
import Spinner from "../spinner";
import { sdkTypes } from "../../constants/genericConstants";

function AddorEditCompany(props) {
  const { handleClose, updateData, type } = props;
  const form = useForm({
    defaultValues: type === "edit" ? async () => fetchCompanyDetails() : {},
  });
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;
  const [makeApiCall, loading] = useApi();
  const orgId = useSelector((state) => state.company?.name);

  const onSubmit = async (data) => {
    Object.keys(data).forEach((i) => {
      if (i === "name" || i === "email") {
        delete data[i];
      }
    });
    const res = await makeApiCall(updateCompanyDetails, orgId, { ...data });
    if (res?.status === 201) {
      toastInstance("success", "Company updated successfully!");
      updateData();
      handleClose();
    }
  };

  const fetchCompanyDetails = async () => {
    const res = await makeApiCall(getCompanyDetails, orgId);
    if (res?.data) {
      const {
        size,
        useCase,
        industry,
        subscriptionType,
        location,
        email,
        name,
        sdkType
      } = res?.data;
      return {
        size,
        useCase,
        industry,
        subscriptionType,
        location,
        email,
        name,
        sdkType
      };
    } else {
      return {};
    }
  };

  const header = type === "edit" ? "Edit Company" : "Add New Company";

  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="lg">
      {loading && <Spinner />}
      <StyledDialogTitle>
        <section className="dialog-header-content">
          <span>{header}</span>
          <div onClick={handleClose}>
            <SVGLoader icon={iconLibrary.CANCEL_ICON} />
          </div>
        </section>
      </StyledDialogTitle>
      <StyledDialogContent>
        <TextField
          label="Company Name"
          name="name"
          id="name"
          validation={register("name", {
            required: "company Name is required",
          })}
          errorMessage={errors.name?.message}
        />
        <TextField
          label="Company Email"
          name="email"
          id="email"
          validation={register("email", {
            required: "company Email is required",
          })}
          errorMessage={errors.email?.message}
        />
        <TextField
          label="Company Location"
          name="location"
          id="location"
          validation={register("location", {
            required: " Company Location is required",
          })}
          errorMessage={errors.location?.message}
        />
        <DropdownField
          label="Company Size"
          name="size"
          id="size"
          options={companySize}
          validation={register("size", {
            required: "Company Size is required",
          })}
          errorMessage={errors.size?.message}
        />
        <DropdownField
          label="Industry"
          name="industry"
          id="industry"
          options={industries}
          validation={register("industry", {
            required: "Industry is required",
          })}
          errorMessage={errors.industry?.message}
        />
        <DropdownField
          label="Use case"
          name="useCase"
          id="useCase"
          options={useCases}
          validation={register("useCase", {
            required: "Use case is required",
          })}
          errorMessage={errors.useCase?.message}
        />
        <DropdownField
          label="Subscription type"
          name="subscriptionType"
          id="subscriptionType"
          options={subscriptionTypes}
          validation={register("subscriptionType", {
            required: "Subscription type is required",
          })}
          errorMessage={errors.subscriptionType?.message}
        />
        <DropdownField
          label="SDK type"
          name="sdkType"
          id="sdkType"
          options={sdkTypes}
          validation={register("sdkType", {
            required: "SDK type is required",
          })}
          errorMessage={errors.sdkType?.message}
        />
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Back
        </Button>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          Save
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
}

export default AddorEditCompany;
