import {ReactComponent as dashboardIcon } from "../assets/icons/dashboard.svg";
import {ReactComponent as analyticsIcon } from "../assets/icons/analytics.svg";
import {ReactComponent as customerIcon } from "../assets/icons/account_circle.svg";
import {ReactComponent as groupIcon } from "../assets/icons/groups.svg";
import {ReactComponent as reportsIcon } from "../assets/icons/reports.svg";
import {ReactComponent as rulesIcon } from "../assets/icons/rules.svg";
import {ReactComponent as transactionsIcon } from "../assets/icons/transactions.svg";
import {ReactComponent as arrowLeftIcon } from "../assets/icons/arrow_circle_left.svg";
import {ReactComponent as arrowRightIcon } from "../assets/icons/arrow_circle_right.svg";
import {ReactComponent as themeMoonIcon } from "../assets/icons/theme_moon.svg";
import {ReactComponent as notificationIcon } from "../assets/icons/notifications.svg";
import {ReactComponent as infoIcon } from "../assets/icons/info.svg";
import {ReactComponent as filterIcon } from "../assets/icons/filter.svg";
import {ReactComponent as AddUser } from "../assets/icons/add_user.svg";
import {ReactComponent as Cancel } from "../assets/icons/cancel_circle.svg";
import {ReactComponent as plusIcon } from "../assets/icons/add_circle.svg";
import {ReactComponent as configIcon } from "../assets/icons/gears.svg";
import {ReactComponent as queryStats } from "../assets/icons/query_stats.svg";
import {ReactComponent as newDevice } from "../assets/icons/new_device.svg";
import {ReactComponent as riskyDevice } from "../assets/icons/blocked_device.svg";
import {ReactComponent as repeatDevice } from "../assets/icons/repeat_device.svg";
import {ReactComponent as device } from "../assets/icons/device.svg";
import {ReactComponent as flag } from "../assets/icons/flag.svg";
import {ReactComponent as search } from "../assets/icons/search.svg";
import {ReactComponent as warning } from "../assets/icons/warning.svg";
import {ReactComponent as downloads } from "../assets/icons/downloads.svg";
import {ReactComponent as upload } from "../assets/icons/upload.svg";
import {ReactComponent as change } from "../assets/icons/change_circle.svg";
import {ReactComponent as integration } from "../assets/icons/integration.svg";
import {ReactComponent as logout } from "../assets/icons/logout.svg";
import {ReactComponent as reset } from "../assets/icons/reset.svg";


const iconLibrary = {
  DASHBOARD_ICON: dashboardIcon,
  TRANSACTION_ICON: transactionsIcon,
  RULES_ICON: rulesIcon,
  ANALYTICS_ICON: queryStats,
  REPORT_ICON: reportsIcon,
  CUSTOMER_ICON: customerIcon,
  GROUP_ICON: groupIcon,
  ARROW_CIRCLE_LEFT: arrowLeftIcon,
  ARROW_CIRCLE_RIGHT: arrowRightIcon,
  INFO_ICON: infoIcon,
  NOTIFICATION: notificationIcon,
  THEME_MOON: themeMoonIcon,
  FILTER_ICON: filterIcon,
  ADD_USER_ICON: AddUser,
  CANCEL_ICON: Cancel,
  PLUS_ICON: plusIcon,
  CONFIGURATIONS_ICON: configIcon,
  DEVICE: device,
  BLOCKED_DEVICE: riskyDevice,
  NEW_DEVICE: newDevice,
  REPEAT_DEVICE: repeatDevice,
  FLAG: flag,
  SEARCH: search,
  WARNING: warning,
  DOWNLOADS: downloads,
  UPLOAD: upload,
  CHANGE: change,
  INTEGRATION: integration,
  LOGOUT: logout,
  RESET: reset,
};


export default iconLibrary