import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { StyledSpinnerBox } from './styles';

export default function Spinner() {
  return (
    <StyledSpinnerBox>
      <CircularProgress />
      <span>Loading.....</span>
    </StyledSpinnerBox>
  );
}