import React, { useState, useMemo, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableToolbar from "../../components/data-table/TableToolbar";
import TableHead from "../../components/data-table/TableHeader";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { StyledTableActionCell } from "../../common-styles";
import { tableHeaders } from "../../constants/tableConfig";
import Switch from "@mui/material/Switch";
import {
  getComparator,
  stableSort,
} from "../../components/data-table/helper-functions";
import AddNewRiskButton from "../add-new-risk";
import useApi from "../../hooks/useApi";
import { deleteRisk, getRisks, updateRisk } from "../../services";
import { toastInstance } from "../../utils/toastInstance";
import { useSelector } from "react-redux";
import Spinner from "../spinner";
import AddorEditRiskDialog from "../dialog/AddorEditRisk";
import Confirmation from "../dialog/Confirmation";
import Pagination from "../data-table/Pagination";

const headCells = tableHeaders["RISK_CONFIGURATION"];

function RiskConfiguration() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0)
  const [makeApiCall, loading] = useApi();
  const { userDetails } = useSelector((state) => state?.user?.userInfo) || {};
  const [selectedRisk, setSelectedRisk] = useState(null);
  const [showEditRisk, setShowEditRisk] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const visibleRows = useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, rows]
  );
  const handleCaseSwitch = async (e, riskId) => {
    const res = await makeApiCall(updateRisk, riskId, {
      active: e.target.checked,
    });
    if (res?.status === 200) {
      fetchRisks();
      toastInstance("success", "Risk updated successfully!");
    }
  };
  const fetchRisks = async (payload = null, limit = 5, offset = 0) => {
    const res = await makeApiCall(getRisks, limit, offset);
    if (res) {
      const { risks, totalRecords } = res?.data;
      setTotalRecords(totalRecords)
      setRows(risks);
    } else {
      toastInstance("error", "Something went wrong!");
    }
  };

  useEffect(() => {
    fetchRisks();
  }, []);

  const handleUpdate = (risk) => {
    setSelectedRisk(risk);
    setShowEditRisk(true);
  };

  const handleDelete = async (risk) => {
    setSelectedRisk(risk);
    setShowConfirm(true);
  };

  const confirmDelete = async () => {
    const res = await makeApiCall(deleteRisk, selectedRisk?.riskId);
    if (res.status === 200) {
      toastInstance("success", "Risk successfully deleted!");
      setShowConfirm(false);
      fetchRisks();
    }
  };

  return (
    <section>
      <Box sx={{ width: "100%" }}>
        {showConfirm && (
          <Confirmation
            handleClose={() => setShowConfirm(false)}
            handleConfirm={confirmDelete}
          />
        )}
        {loading && <Spinner />}
        {showEditRisk && (
          <AddorEditRiskDialog
            handleClose={() => setShowEditRisk(false)}
            updateList={fetchRisks}
            riskData={selectedRisk}
            isEdit
          />
        )}
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableToolbar
            title={"Risk Configuration"}
            disableSearch
            compoentToRender={
              userDetails?.role.includes("Admin")  && (
                <AddNewRiskButton updateList={fetchRisks} />
              )
            }
          />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="Risk Configuration">
              <TableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length}
                headCells={headCells}
              />
              <TableBody>
                {visibleRows.length> 0 && visibleRows.map((row) => (
                  <TableRow hover key={row.riskId} sx={{ cursor: "pointer" }}>
                    <TableCell align="left">{row.minScore}</TableCell>
                    <TableCell align="left">
                      <div
                        style={{
                          background: row.tagColor,
                          width: "max-content",
                          padding: "2px 15px",
                          color: "#fff",
                          fontWeight: "600",
                          borderRadius: "6px",
                        }}
                      >
                        {row.riskName}
                      </div>
                    </TableCell>
                    <TableCell align="left">{row.maxScore}</TableCell>
                    <TableCell align="left">
                      <StyledTableActionCell>
                        <Switch
                          checked={row.active === "true"}
                          onChange={(e) => handleCaseSwitch(e, row.riskId)}
                        />
                      </StyledTableActionCell>
                    </TableCell>
                    <TableCell align="left">
                      <StyledTableActionCell>
                        {(userDetails?.role.includes("Admin")  ||
                          userDetails?.role === "Editor") && (
                          <BorderColorOutlinedIcon
                            sx={{ color: "#3965FF" }}
                            onClick={() => handleUpdate(row)}
                          />
                        )}
                        {userDetails?.role.includes("Admin")  && (
                          <DeleteOutlinedIcon
                            sx={{ color: "#BD041A" }}
                            onClick={() => handleDelete(row)}
                          />
                        )}
                      </StyledTableActionCell>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalRecords}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            fetchData={fetchRisks}
          />
        </Paper>
      </Box>
    </section>
  );
}

export default RiskConfiguration;
