import React, {useState} from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import { StyledUserInfoWrapper } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import { toastInstance } from "../../utils/toastInstance";
import { setUser } from "../../redux/features/userSlice";
import SVGLoader from "../svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import { setCompanyName } from "../../redux/features/companySlice";
import routesConfig from "../../constants/routeConfig";
import AddorEditCompany from "../dialog/AddorEditCompany";

export default function AccountMenu({ anchorEl, handleClose }) {
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.user);
  const { userName, userDetails } = userInfo;
  const [showDialog, setShowDialog] = useState(false)
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    dispatch(setUser({}));
    dispatch(setCompanyName(""));
    navigate("/login");
    toastInstance("success", "User logged out successfully!");
  };
  const lastLoginInfo = userDetails?.lastLogin?.join(" ");
  const currentPath = useLocation();
  const isSuperAdminConsole = currentPath.pathname === "/super-admin/console"
  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "account",
        }}
      >
        <MenuItem>
          <Avatar />
          <StyledUserInfoWrapper>
            <span>{userName}</span>
            <span className="account-role">{userDetails?.role}</span>
            <span className="account-timestamp">
              Last login: {lastLoginInfo}
            </span>
          </StyledUserInfoWrapper>
        </MenuItem>
        <Divider />
        {userDetails?.role === "Admin" && (
          <>
            <MenuItem
              onClick={() => navigate(routesConfig.INTEGRATION_CONSOLE)}
            >
              <ListItemIcon>
                <SVGLoader icon={iconLibrary.INTEGRATION} />
              </ListItemIcon>
              Integration Console
            </MenuItem>
            <Divider />
          </>
        )}
        {!isSuperAdminConsole && userDetails?.role === "SuperAdmin" && (
          <>
            <MenuItem onClick={() => setShowDialog(true)}>
              <ListItemIcon>
                <SVGLoader icon={iconLibrary.INTEGRATION} />
              </ListItemIcon>
              Update profile
            </MenuItem>
            <Divider />
          </>
        )}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <SVGLoader icon={iconLibrary.LOGOUT} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      {showDialog && (
        <AddorEditCompany
          handleClose={() => setShowDialog(false)}
          type="edit"
        />
      )}
    </div>
  );
}
