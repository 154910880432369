import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableToolbar from "../../components/data-table/TableToolbar";
import Pagination from "../../components/data-table/Pagination";
import TableHead from "../../components/data-table/TableHeader";
import {
  getComparator,
  stableSort,
  generateEmptyRows,
} from "../../components/data-table/helper-functions";
import { tableHeaders } from "../../constants/tableConfig";
import LinearProgressWithLabel from "../../components/progress-bar";
import {
  StyledButton,
  StyledIconButtonWrapper,
  StyledTableActionCell,
  StyledTableMultiRow,
} from "../../common-styles";
import TransactionsDialogBox from "../../components/dialog/FilterTransactions";
import SVGLoader from "../../components/svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import TransactionDetails from "../../components/dialog/TransactionDeatils";
import Spinner from "../../components/spinner";
import { getTransactions, searchTransactions } from "../../services";
import useApi from "../../hooks/useApi";
import { formatTimeStamp } from "../../utils/commonUtils";
import { useDispatch, useSelector } from "react-redux";
import { removeSearchCriteria } from "../../redux/features/searchSlice";

const headCells = tableHeaders["TRANSACTIONS"];

const FilterTransactions = (props) => {
  const { updateList } = props;
  const [showDialog, setShowDialog] = useState(false);
  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };
  return (
    <>
      {showDialog && (
        <TransactionsDialogBox
          handleClose={closeDialog}
          updateList={updateList}
        />
      )}
      <StyledIconButtonWrapper onClick={openDialog}>
        <SVGLoader icon={iconLibrary.FILTER_ICON} />
      </StyledIconButtonWrapper>
    </>
  );
};

export default function Transactions() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0)
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [rows, setRows] = useState([]);
  const [makeApiCall, loading] = useApi();
  const { searchCriteria } = useSelector((state) => state.search);
  const dispatch = useDispatch()

  const fetchTransactions = async (filterPayload, pageSize=5, startIndex=0) => {
    const payload = filterPayload || {
      searchCriteria: [],
      pageSize: pageSize || 5,
      startIndex: startIndex || 0,
    };

    const res = await makeApiCall(getTransactions, payload);
    if (res) {
      setRows(res.data.transactionList);
      setTotalRecords(res.data.totalRecords);
    } else {
      return;
    }
  };

  const searchTransactionsData = async (
    searchPayload,
    pageSize = 5,
    startIndex = 0
  ) => {
    const payload = searchPayload
      ? { ...searchPayload, pageSize, startIndex }
      : {
          ...searchCriteria,
          pageSize,
          startIndex,
        };
    const res = await makeApiCall(searchTransactions, payload);
    if (res) {
      setRows(res.data.transactionList);
      setTotalRecords(res.data.totalRecords);
    }
  };

  useEffect(() => {
    fetchTransactions();
    return () => {
      dispatch(removeSearchCriteria())
    }
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const showtransactionDetails = (id) => {
    setSelectedTransaction(id)
    setShowDetailsDialog(true);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = generateEmptyRows(page, rowsPerPage, rows);

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Box sx={{ width: "100%" }}>
      {loading && <Spinner />}
      {showDetailsDialog && (
        <TransactionDetails
          handleClose={() => setShowDetailsDialog(false)}
          transactionId={selectedTransaction}
        />
      )}
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableToolbar
          title={"Transactions"}
          component={'TRANSACTIONS'}
          fetchData={searchTransactionsData}
          fetchAllData={fetchTransactions}
          filterComponent={
            <FilterTransactions updateList={fetchTransactions} />
          }
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {visibleRows.length> 0 && visibleRows.map((row) => {
                return (
                  <TableRow
                    hover
                    key={row.transactionId}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell align="left">
                      <StyledTableActionCell>
                        <SVGLoader icon={iconLibrary.FLAG} color={row.isCase === 'true' && 'red'}/>
                        <StyledTableMultiRow>
                          <span>
                            {formatTimeStamp(row.transactionTimeStamp)[0]}
                          </span>
                          <span>
                            {formatTimeStamp(row.transactionTimeStamp)[1]}
                          </span>
                        </StyledTableMultiRow>
                      </StyledTableActionCell>
                    </TableCell>
                    <TableCell align="left">{row.transactionId}</TableCell>
                    <TableCell align="left">{row.deviceId}</TableCell>
                    <TableCell align="left">{row.userId}</TableCell>
                    <TableCell align="left">{row.listed}</TableCell>
                    <TableCell align="left">
                      <LinearProgressWithLabel
                        value={Math.round(row.deviceMatchScore * 100)}
                      />
                    </TableCell>
                    <TableCell align="left">{row.riskScore}</TableCell>
                    <TableCell align="left">{row.riskSuggestion}</TableCell>
                    <TableCell align="left">
                      <StyledButton
                        onClick={() =>
                          showtransactionDetails(row.transactionId)
                        }
                      >
                        Details
                      </StyledButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          count={totalRecords}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          fetchData={fetchTransactions}
          searchData={searchTransactionsData}
        />
      </Paper>
    </Box>
  );
}
