import React from "react";
import SVGLoader from "../svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import { StyledDashbardErrorWrapper } from "../dashboard-components/styles";

function DataLoadError({ message }) {
  return (
    <StyledDashbardErrorWrapper>
      <SVGLoader icon={iconLibrary.WARNING} color="#FF9900" />
      {message ? <p>{message}</p> : <p>No data found!!! Please try again...</p>}
    </StyledDashbardErrorWrapper>
  );
}

export default DataLoadError;
