import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "../form-elements/text-field";
import {
  StyledDialogTitle,
  StyledDialogActions,
  StyledDialogContent,
} from "./styles";
import DropdownField from "../form-elements/drop-down";
import { employeeTypeList, roleList } from "../../constants/genericConstants";
import SVGLoader from "../svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import { toastInstance } from "../../utils/toastInstance";
import { createUser, getLocations } from "../../services";
import DataList from "../form-elements/data-list";
import useApi from "../../hooks/useApi";
import { useForm } from "react-hook-form";
import { emailRegex } from "../../utils/commonUtils";

function AddUserDialog(props) {
  const { handleClose } = props;
  const form = useForm();
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;
  const [location, setLocation] = useState("");
  const [locations, setLocations] = useState([]);
  const [makeApiCall, loading] = useApi();

  useEffect(() => {
    searchLocations(location);
  }, [location]);

  const searchLocations = async (value) => {
    if (value) {
      try {
        const res = await getLocations(value);
        setLocations(res.data);
      } catch (error) {
        toastInstance("error", "Unable to load the locations!");
      }
    }
  };

  const onSubmit = async (data) => {
    const res = await makeApiCall(createUser, data);
    if (res?.status === 201) {
      toastInstance("success", "User created successfully!");
      props.updateList();
      handleClose();
    }
  };

  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="lg">
      <StyledDialogTitle>
        <section className="dialog-header-content">
          <span>Add New User Details</span>
          <div onClick={handleClose}>
            <SVGLoader icon={iconLibrary.CANCEL_ICON} />
          </div>
        </section>
      </StyledDialogTitle>
      <StyledDialogContent>
        <TextField
          label="Username"
          name="userName"
          id="userName"
          validation={register("userName", {
            required: " Username is required",
          })}
          errorMessage={errors.userName?.message}
        />
        <TextField
          label="Email ID"
          name="emailId"
          id="emailId"
          validation={register("emailId", {
            required: " Email Id is required",
            pattern: {
              value: emailRegex,
              message: "Please enter a valid Email Id",
            },
          })}
          errorMessage={errors.emailId?.message}
        />
        <DropdownField
          label="Role"
          name="role"
          options={roleList}
          id="role"
          validation={register("role", {
            required: "Role is required",
          })}
          errorMessage={errors.role?.message}
        />
        <DataList
          label="Location"
          options={locations}
          name="location"
          listid="location-list"
          id="location"
          validation={register("location", {
            required: "Location is required",
          })}
          errorMessage={errors.location?.message}
        />
        <DropdownField
          label="Employee Type"
          name="empType"
          options={employeeTypeList}
          id="empType"
          validation={register("empType", {
            required: "Employee Type is required",
          })}
          errorMessage={errors.empType?.message}
        />
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Back
        </Button>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          Apply
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
}

export default AddUserDialog;
