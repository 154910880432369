import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { StyledBox } from "../../common-styles";
import RiskConfiguration from "../../components/risk-configuration";
import Lists from "../../components/lists";
import Groups from "../../components/groups";
import { StyledConfigurationsWrapper } from "./styles";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Configuration() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <StyledBox>
      {
        <StyledConfigurationsWrapper>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Risks" {...a11yProps(0)} />
              <Tab label="Lists" {...a11yProps(1)} />
              <Tab label="Groups" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <RiskConfiguration/>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Lists/>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Groups/>
          </CustomTabPanel>
        </StyledConfigurationsWrapper>
      }
    </StyledBox>
  );
}

export default Configuration;
