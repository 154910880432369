import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SearchField from "./SearchField";

function TableToolbar(props) {
  const {
    title,
    compoentToRender,
    filterComponent,
    disableSearch,
    fetchData,
    component,
    fetchAllData,
  } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { sm: 2 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {title}
      </Typography>
      {!disableSearch && (
        <SearchField
          component={component}
          fetchData={fetchData}
          resetSearch={fetchAllData}
        />
      )}
      {compoentToRender}
      {filterComponent}
    </Toolbar>
  );
}

export default TableToolbar;
