import React, { useRef, useState } from "react";
import { StyledResetButton, StyledSearchFieldWrapper } from "./styles";
import SVGLoader from "../svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import {
  setSearchCriteria,
  removeSearchCriteria,
} from "../../redux/features/searchSlice";
import { useDispatch } from "react-redux";
import { emailRegex } from "../../utils/commonUtils";
import { toastInstance } from "../../utils/toastInstance";
import Tooltip from '@mui/material/Tooltip';

const searchRules = {
  CUSTOMER_ANALYTICS: {
    fieldRules: [
      {
        field: "email",
        pattern: emailRegex,
      },
    ],
    errorMessage: "Invalid search! Please enter a valid email id!!",
  },
  USER_MANAGEMENT: {
    fieldRules: [
      {
        field: "emailId",
        pattern: emailRegex,
      },
    ],
    errorMessage: "Invalid search! Please enter a valid email id!!",
  },
  TRANSACTIONS: {
    fieldRules: [
      {
        field: "userId",
        pattern: emailRegex,
      },
      {
        field: "transactionId",
        pattern: /^DW_/,
      },
      {
        field: "deviceId",
        pattern:
          /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
      },
    ],
    errorMessage:
      "Invalid search! Please enter a valid user id, transaction id or device id!!",
  },
  RISK_RULES: {
    fieldRules: [
      {
        field: "rule_id",
        pattern:
          /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
      },
      {
        field: "rule_name",
        pattern: /^.*$/,
      },
    ],
    errorMessage: "Invalid search! Please enter a valid rule id or rule name!!",
  },
  LISTS: {
    fieldRules: [
      {
        field: "fieldValue",
        pattern: /.+/,
      },
    ],
    errorMessage: "Invalid search! Please enter a valid field value!!",
  },
  GROUPS: {
    fieldRules: [
      {
        field: "fieldValue",
        pattern: /.+/,
      },
    ],
    errorMessage:
      "Invalid search! Please enter a valid field value or group name!!",
  },
};

function SearchField({ component, fetchData, resetSearch }) {
  const searchRef = useRef();
  const [isSearched, setIsSearched] = useState(false);
  const dispatch = useDispatch();
  const handleSearch = () => {
    const searchRule = searchRules[component];
    const searchValue = searchRef?.current?.value;
    const searchField = searchRule?.fieldRules?.find((i) => {
      return i.pattern?.test(searchValue);
    });
    if (!searchField) {
      toastInstance(
        "error",
        searchRule?.errorMessage || "Something went wrong!"
      );
    } else {
      const payload = {
        searchField: searchField.field,
        searchValue: searchValue,
      };
      dispatch(setSearchCriteria(payload));
      setIsSearched(true);
      fetchData && fetchData(payload);
    }
  };

  const handleSearchReset = () => {
    setIsSearched(false);
    resetSearch();
    removeSearchCriteria();
    if (searchRef?.current) {
      searchRef.current.value = "";
    }
  };

  return (
    <>
      <StyledSearchFieldWrapper className="wrapper">
        <input type="text" placeholder={"Search"} ref={searchRef} />
        <button onClick={handleSearch}>
          <SVGLoader icon={iconLibrary.SEARCH} color="#2B3674" />
        </button>
      </StyledSearchFieldWrapper>
      {isSearched && (
        <Tooltip title={"Reset search"} arrow>
          <StyledResetButton onClick={handleSearchReset}>
            <SVGLoader icon={iconLibrary.RESET} color="#2B3674" />
          </StyledResetButton>
        </Tooltip>
      )}
    </>
  );
}

export default SearchField;
