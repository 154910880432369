const roleList = ["Admin", "Editor", "Viewer"];

const employeeTypeList = ["Permanent", "Contract"];

const transactionTypeList = ["Whitelisted", "Blacklisted"];

const ruleModeList = ["Live", "Test"];

const ruleStatusList = ["Active", "Inactive"];

const userStatusList = ["Active", "Inactive"];

const groupStatus = ["Active", "Inactive"];

const listClassification = ["Whitelisted", "Blacklisted"];

const colors = [
  { hex: "#00759A", name: "Blue" },
  { hex: "#371D10", name: "Brown" },
  { hex: "#8D021F", name: "Burgundy" },
  { hex: "#0B6623", name: "Green" },
  { hex: "#800000", name: "Maroon" },
  { hex: "#F7941D", name: "Orange" },
  { hex: "#9F2B68", name: "Purple" },
  { hex: "#BD041A", name: "Red" },
  { hex: "#039590", name: "Teal" },
];

const reportTypes = [
  "browser", "list"
]

const sdkTypes = [
  "devicewithbb",
  "device",
  "biometrics",
]

export {
  roleList,
  employeeTypeList,
  transactionTypeList,
  ruleModeList,
  ruleStatusList,
  colors,
  groupStatus,
  listClassification,
  reportTypes,
  sdkTypes,
  userStatusList
};
