import styled from "styled-components";

export const StyledInputWrapper = styled.section`
  display: flex;
  flex-direction: column;
  select {
    height: 36px;
    border-radius: 6px;
    border: 2px solid #e0e5f2;
    padding: 0 5px;
    color: #a3aed0;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    letter-spacing: -0.28px;
    &:focus {
      border: 2px solid #2B3674;
      outline: none;
    }
    &.with-error{
      border: 2px solid #bd041a;
    }
  }
  label {
    color: #2b3674;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    margin-bottom: 5px;
  }
  .error-msg{
    color: #bd041a;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    margin-top: 5px;
  }
`;
