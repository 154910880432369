import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { StyledTooltip } from "./styles";
import DataLoadError from "../load-error-warning/DashboardLoadError";
import { formatDateTimeToString } from "../../utils/commonUtils";

function UserCountGraph(props) {
  const {data, isFetching} = props
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <StyledTooltip>
          <p>{`${label}`}</p>
          <p className="label">{`Total users : ${payload[0].payload.totalUsers}`}</p>
          <p className="label">{`Repeat users : ${payload[0].payload.repeatUsers}`}</p>
          <p className="label">{`New users : ${payload[0].payload.newUsers}`}</p>
        </StyledTooltip>
      );
    }
    return null;
  };
  const processedData = data?.map((i) => {
    return { ...i, timeStamp: formatDateTimeToString(i.timeStamp) };
  });
  return (
    <ResponsiveContainer>
      {processedData?.length>0 &&
      <LineChart
        data={processedData}
        margin={{
          top: 15,
          right: 10,
          left: 0,
          bottom: 25,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis dataKey="timeStamp" tickLine={false} axisLine={false} />
        <YAxis axisLine={false} tickLine={false} />
        <Tooltip content={CustomTooltip}/>
        <Line
          type="monotone"
          dataKey="totalUsers"
          stroke="#00E096"
          activeDot={{ r: 8 }}
        />
      </LineChart>}
      {!isFetching && (processedData?.length === 0 || !processedData) && <DataLoadError />}
    </ResponsiveContainer>
  );
}

export default UserCountGraph;
