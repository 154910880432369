import menuList from "../constants/sidebarContent";
import { toastInstance } from "./toastInstance";

const findActiveMenu = (path) => {
  if(path === '/downloads'){
    return -1
  }
  const activeMenu =
    menuList.find((i) => i.routePath === path) ||
    menuList.find((i) => i.subPaths?.includes(path));
  return activeMenu?.id;
};

const isObjectEmpty = (obj) => {
  if (!obj) {
    return true;
  }
  return Object.keys(obj).length > 0 ? false : true;
};

const formatTimeStamp = (timeStamp) => {
  return new Date(Number(timeStamp)).toLocaleString().split(",");
};

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ipAndIprangeregex =
  /^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?:-(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}))?(?:,(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?:-(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}))?)*$/;

const getOnlyDirtyFields = (dirtyFields, formData) => {
  const modifiedData = {};
  Object.keys(dirtyFields).forEach((i) => {
    modifiedData[i] = formData[i];
  });
  if (isObjectEmpty(modifiedData)) {
    toastInstance("error", "No changes to submit!");
    return null;
  }
  return modifiedData;
};

const startAndCurrentTimeStamp = () => {
  // Get the current date
  const currentDate = new Date();

  // Set the time to the start of the day (00:00:00)
  currentDate.setHours(0, 0, 0, 0);

  // Get the timestamp for the start of today
  const startOfDayTimestamp = currentDate.getTime();

  // Get the current timestamp
  const currentTimestamp = new Date().getTime();

  // Format the timestamps in the desired format in local time
  const formattedStartOfDay = formatDate(new Date(startOfDayTimestamp));
  const formattedCurrentTimestamp = formatDate(new Date(currentTimestamp));
  return {
    startOfToday: formattedStartOfDay,
    currentTimeStamp: formattedCurrentTimestamp,
  };
};

// Function to format date as "YYYY-MM-DD HH:mm:ss"
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const formatDateTimeToString = (inputDateString) => {
  const modifiedDate = inputDateString.replace(" ", "T").concat(":00:00");
  const inputDate = new Date(modifiedDate);
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return inputDate.toLocaleString("en-US", options);
};

export {
  findActiveMenu,
  isObjectEmpty,
  formatTimeStamp,
  emailRegex,
  getOnlyDirtyFields,
  ipAndIprangeregex,
  startAndCurrentTimeStamp,
  formatDateTimeToString,
  formatDate
};
