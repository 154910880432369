import React from "react";
import Dialog from "@mui/material/Dialog";
import TextField from "../form-elements/text-field";
import { StyledDialogTitle, StyledDialogContent, StyledDialogActions } from "./styles";
import SVGLoader from "../svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import Button from "@mui/material/Button";

function UserDetailsDialogBox(props) {
  const { userData, handleClose } = props;

  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="lg">
      <StyledDialogTitle>
        <section className="dialog-header-content">
          <span>User Details</span>
          <div onClick={handleClose}>
            <SVGLoader icon={iconLibrary.CANCEL_ICON} />
          </div>
        </section>
      </StyledDialogTitle>
      <StyledDialogContent>
        <TextField
          readOnly
          label="Username"
          name="userName"
          value={userData?.userName || ""}
        />
        <TextField
          readOnly
          label="User ID"
          name="userId"
          value={userData?.userId || ""}
        />
        <TextField
          readOnly
          label="Email ID"
          name="emailId"
          value={userData?.emailId || ""}
        />
        <TextField
          readOnly
          label="Role"
          name="role"
          value={userData?.role || ""}
        />
        <TextField
          readOnly
          label="Location"
          name="location"
          value={userData?.location || ""}
        />
        <TextField
          readOnly
          label="Profile Expiry"
          name="profileExpiry"
          value={userData?.profileExpiry || ""}
        />
        <TextField
          readOnly
          label="Employee Type"
          name="employeeType"
          value={userData?.empType || ""}
        />
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
}

export default UserDetailsDialogBox;
