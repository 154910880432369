import { useSelector } from "react-redux";
import { standardOperators } from "../../constants/rulesConstants";

const CustomValueEditor = (props) => {
  const { operator, handleOnChange, value } = props;
  const operatorsMetaData = useSelector(
    (state) => state.rules?.operatorsAndFields.operatorMetaData
  );
  const selectedOperatorData = operatorsMetaData?.find(
    (i) => i.operator === operator
  );

  const isStandardOpUsed = standardOperators.some((i) => i.name === operator);

  const selectedOperatorDataKeys =
    selectedOperatorData && Object.keys(selectedOperatorData);

  const handleChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    handleOnChange({ ...value, [fieldName]: fieldValue });
  };

  const listOptions = selectedOperatorData?.list?.map((i) => {
    return { value: i, label: i };
  });

  const primaryIdOptions = selectedOperatorData?.primaryId?.map((i) => {
    return { value: i, label: i };
  });

  const altEntityOptions = selectedOperatorData?.altEntity?.map((i) => {
    return { value: i, label: i };
  });
  return (
    <>
      {isStandardOpUsed && (
        <input onChange={handleChange} name="std_op_value" value={value.std_op_value} />
      )}
      {selectedOperatorDataKeys?.includes("list") && (
        <select onChange={handleChange} name="list" value={value.list}>
          <option value="">Select</option>
          {listOptions?.length > 0 &&
            listOptions.map((i) => <option value={i.value}>{i.value}</option>)}
        </select>
      )}
      {selectedOperatorDataKeys?.includes("primaryId") && (
        <>
          <select
            onChange={handleChange}
            name="primaryId"
            value={value.primaryId}
          >
            <option value="">Select</option>
            {primaryIdOptions?.length > 0 &&
              primaryIdOptions.map((i) => (
                <option value={i.value}>{i.value}</option>
              ))}
          </select>
          <input onChange={handleChange} name="input1" value={value.input1} />
        </>
      )}
      {selectedOperatorDataKeys?.includes("altEntity") && (
        <>
          <select
            onChange={handleChange}
            value={value.altEntity}
            name="altEntity"
          >
            <option value="">Select</option>
            {altEntityOptions?.length > 0 &&
              altEntityOptions.map((i) => (
                <option value={i.value}>{i.value}</option>
              ))}
          </select>
          <input onChange={handleChange} name="input2" value={value.input2} />
        </>
      )}
    </>
  );
};

export default CustomValueEditor;
