import React from "react";
import Dialog from "@mui/material/Dialog";
import {
  StyledConfirmationTitle,
  StyledConfirmationContent,
  StyledConfirmationActions,
} from "./styles";
import SVGLoader from "../svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

function Confirmation(props) {
  const { handleClose, handleConfirm } = props;

  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="xs">
      <StyledConfirmationTitle>
        <section>
          <div onClick={handleClose}>
            <SVGLoader icon={iconLibrary.CANCEL_ICON} />
          </div>
        </section>
      </StyledConfirmationTitle>
      <StyledConfirmationContent>
        <SVGLoader icon={iconLibrary.CANCEL_ICON} color={'#BD041A'}/>
        <Typography variant="h4" gutterBottom>
          Are you sure?
        </Typography>
        <Typography variant="body1" gutterBottom>
          Do you really want to delete these records? This process cannot be
          undone.
        </Typography>
      </StyledConfirmationContent>
      <StyledConfirmationActions>
        <Button className="cnfm-cancel" variant="contained" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleConfirm}>
          Delete
        </Button>
      </StyledConfirmationActions>
    </Dialog>
  );
}

export default Confirmation;
