import React, { useState, useMemo, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableToolbar from "../../components/data-table/TableToolbar";
import Pagination from "../../components/data-table/Pagination";
import TableHead from "../../components/data-table/TableHeader";
import {
  getComparator,
  stableSort,
  generateEmptyRows,
} from "../../components/data-table/helper-functions";
import { tableHeaders } from "../../constants/tableConfig";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import UserDialogBox from "../../components/dialog/UserDetails";
import { StyledButton, StyledTableActionCell, StyledTableMultiRow } from "../../common-styles";
import EditUser from "../../components/dialog/EditUser";
import { toastInstance } from "../../utils/toastInstance";
import { deleteUser, getUsers, searchUsers } from "../../services";
import Spinner from "../../components/spinner";
import Confirmation from "../../components/dialog/Confirmation";
import FilterUsers from "../../components/filter-users";
import AddNewUserButton from "../../components/add-new-user";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../hooks/useApi";
import { formatTimeStamp } from "../../utils/commonUtils";
import { removeSearchCriteria } from "../../redux/features/searchSlice";

const headCells = tableHeaders["USER_MANAGEMENT"];

export default function UserManagement() {
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0)
  const [selectedUser, setSelectedUser] = useState(null);
  const [showEditUser, setShowEditUser] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [makeApiCall, loading] = useApi()
  const { searchCriteria } = useSelector((state) => state.search);
  const { userDetails } = useSelector((state) => state?.user?.userInfo)|| {};
  const dispatch = useDispatch()

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const fetchUsers = async (filterPayload, pageSize, startIndex) => {
    const payload = filterPayload || {
      searchCriteria: [],
      pageSize: pageSize || 5,
      startIndex: startIndex || 0,
    };
    const res = await makeApiCall(getUsers, payload);
    if (res) {
      setRows(res.data.userList);
      setTotalRecords(res.data.totalRecords);
    } else {
      return;
    }
  };

  const searchUserData = async (
    searchPayload,
    pageSize = 5,
    startIndex = 0
  ) => {
    const payload = searchPayload
      ? { ...searchPayload, pageSize, startIndex }
      : {
          ...searchCriteria,
          pageSize,
          startIndex,
        };
    const res = await makeApiCall(searchUsers, payload);
    if (res) {
      setRows(res.data);
      setTotalRecords(res.data.length);
    }
  };

  useEffect(() => {
    fetchUsers();
    return () => {
      dispatch(removeSearchCriteria())
    }
  }, []);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = generateEmptyRows(page, rowsPerPage, rows);

  const visibleRows = useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const openDetailsDialog = (user) => {
    setSelectedUser(user);
    setShowDetailsDialog(true);
  };

  const closeDetailsDialog = () => {
    setShowDetailsDialog(false);
  };

  const confirmDelete = async () => {
    const res = await makeApiCall(deleteUser, selectedUser?.userId);
    if (res.response.status === 200) {
      toastInstance("success", "User successfully deleted!");
      setShowConfirm(false);
      fetchUsers();
    }
  };

  const handleDelete = async (user) => {
    setSelectedUser(user);
    setShowConfirm(true)
  };

  const handleUpdate = (user) => {
    setSelectedUser(user);
    setShowEditUser(true);
  };

  return (
    <section>
      {showDetailsDialog && (
        <UserDialogBox
          userData={selectedUser}
          handleClose={closeDetailsDialog}
        />
      )}
      {showConfirm && (
        <Confirmation
          handleClose={() => setShowConfirm(false)}
          handleConfirm={confirmDelete}
        />
      )}
      {loading && <Spinner />}
      {showEditUser && (
        <EditUser
          handleClose={() => setShowEditUser(false)}
          updateList={fetchUsers}
          userData={selectedUser}
        />
      )}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableToolbar
            title={"Customer Management"}
            component={'USER_MANAGEMENT'}
            fetchData={searchUserData}
            fetchAllData={fetchUsers}
            compoentToRender={
              userDetails?.role.includes("Admin") && (
                <AddNewUserButton updateList={fetchUsers} />
              )
            }
            filterComponent={<FilterUsers updateList={fetchUsers} />}
          />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="Customer Management">
              <TableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length}
                headCells={headCells}
              />
              <TableBody>
                {visibleRows.length> 0 && visibleRows.map((row) => (
                  <TableRow hover key={row.userId} sx={{ cursor: "pointer" }}>
                    <TableCell align="left">{row.userName}</TableCell>
                    <TableCell align="left">{row.userId}</TableCell>
                    <TableCell align="left">{row.emailId}</TableCell>
                    <TableCell align="left">{row.role}</TableCell>
                    <TableCell align="left">
                      {row.lastLogin ? (
                        <StyledTableMultiRow>
                          <span>{formatTimeStamp(row.lastLogin)[0]}</span>
                          <span>{formatTimeStamp(row.lastLogin)[1]}</span>
                        </StyledTableMultiRow>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell align="left">{row.status}</TableCell>
                    <TableCell align="left">
                      <StyledTableActionCell>
                        {(userDetails?.role.includes("Admin")  ||
                          userDetails?.role === "Editor") && (
                          <BorderColorOutlinedIcon
                            sx={{ color: "#3965FF" }}
                            onClick={() => handleUpdate(row)}
                          />
                        )}
                        {userDetails?.role.includes("Admin")  && (
                          <DeleteOutlinedIcon
                            sx={{ color: "#BD041A" }}
                            onClick={() => handleDelete(row)}
                          />
                        )}
                        <StyledButton
                          onClick={() => openDetailsDialog(row)}
                        >
                          Details
                        </StyledButton>
                      </StyledTableActionCell>
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalRecords}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            fetchData={fetchUsers}
            searchData={searchUserData}
          />
        </Paper>
      </Box>
    </section>
  );
}
