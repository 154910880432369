import styled from 'styled-components'

export const StyledUserInfoWrapper = styled.section`
    font-size: DM Sans;
    display: flex;
    flex-direction:column;
    padding-left: 15px;
    color: #05004e;
    font-size: 14px;
    .account-role{
        font-size: 12px;
        color: #4318FF;
    }
    .account-timestamp{
        font-size: 10px;
        color: #A3AED0;
    }
`