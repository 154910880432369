import styled from "styled-components";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export const StyledDialogTitle = styled(DialogTitle)`
  .dialog-header-content{
    color: #2b3674;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; 
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  div{
    cursor: pointer;
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: center !important;
  margin-bottom: 25px;
`;

export const StyledDialogContent = styled(DialogContent)`
  display: ${props => (props.bulkuploader ? 'flex' : 'grid')};
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;
  .uploadBox{
    width: 100%;
    svg{
      display: none;
    }
  }
`;

export const StyledConfirmationContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 40px;
  svg{
    width: 60px;
    height: 60px;
  }
  h4{
    color: #5A5A5A;;
    font-family:'DM Sans';
  }
  p{
    color: #bababa;
  }
`;

export const StyledConfirmationActions = styled(DialogActions)`
  display: flex;
  justify-content: center !important;
  margin-bottom: 25px;
  button{
    background-color: #BD041A;
    &.cnfm-cancel{
      background-color: #bababa;
    }
  }
`;

export const StyledConfirmationTitle = styled(DialogTitle)`
  section {
    color: #2b3674;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  div{
    cursor: pointer;
  }
`;

export const StyledTransactionDetailsTable = styled.table`
  background-color: #fff;
  width: 100%;
  border: 0px;
  border-collapse: collapse;
  thead {
    background-color: #e9edf7;
  }
  th {
    text-align: left;
    padding: 15px;
    color: #454545;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; 
  }
  td {
    padding: 15px;
    padding-right: 100px;
    color: #464e5f;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid rgba(224, 224, 224, 1)
  }
`;

export const StyledCenterAlignedTextContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: DM Sans;
`