import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DataLoadError from "../load-error-warning/DashboardLoadError";

function TopCustomersTable(props) {
  const {data, isFetching} = props;
  const processedData = data?.sort((a,b)=>b.noOfVisitsPerDay - a.noOfVisitsPerDay).slice(0,5)
  return (
    <TableContainer component={Paper}>
      <Table aria-label="Top customers table">
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell align="right">No of visits</TableCell>
            <TableCell align="right">Location</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {processedData?.length>0 && processedData.map((row) => (
            <TableRow
              key={row.emailId}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.emailId}
              </TableCell>
              <TableCell align="right">{row.noOfVisitsPerDay}</TableCell>
              <TableCell align="right">{row.location}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!isFetching && (data?.length === 0 || !data) && <DataLoadError />}
    </TableContainer>
  );
}

export default TopCustomersTable;
