import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/features/userSlice";
import { toastInstance } from "../../utils/toastInstance";
import { loginUser } from "../../services";
import { useNavigate } from "react-router-dom";
import { formatTimeStamp, isObjectEmpty } from "../../utils/commonUtils";
import { STyledFieldError } from "../../common-styles";
import routesConfig from "../../constants/routeConfig";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      © 2023 DWield.ai. All Rights Reserved.
    </Typography>
  );
}

const defaultTheme = createTheme();

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({ emailId: "", password: "" });
  const [submitted, setSubmitted] = useState(false);

  const isEmailValid = (email) => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    return emailRegex.test(email);
  };

  const isPasswordValid = (password) => {
    return password.length > 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Check for validity and update the error state only if the form is submitted
    if (submitted) {
      const newErrors = { ...errors };
      if (name === "emailId") {
        if (!isEmailValid(value)) {
          newErrors.emailId = "Please enter a valid email address";
        } else {
          delete newErrors.emailId;
        }
      }
      if (name === "password") {
        if (!isPasswordValid(value)) {
          newErrors.password = "Password is required";
        } else {
          delete newErrors.password;
        }
      }
      setErrors(newErrors);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { emailId, password } = formData;
    const newErrors = {};

    if (!isEmailValid(emailId)) {
      newErrors.emailId = "Please enter a valid email address";
    }

    if (!isPasswordValid(password)) {
      newErrors.password = "Password is required";
    }

    setErrors(newErrors);
    setSubmitted(true);

    if (isObjectEmpty(newErrors)) {
      try {
        const res = await loginUser(formData);
        if (res.status === 200) {
          localStorage.setItem("authToken", res.data.jwToken);
          dispatch(
            setUser({
              ...res.data,
              userDetails: {
                ...res.data?.userDetails,
                lastLogin: formatTimeStamp(new Date()),
              },
            })
          );
          if (res.data?.userDetails.role === "SuperAdmin") {
            navigate(routesConfig.SUPER_ADMIN_CONSOLE);
          } else {
            navigate(routesConfig.DASHBOARD);
          }
          toastInstance("success", "User logged in successfully!");
        }
      } catch (error) {
        toastInstance("error", error.response?.data?.error);
      }
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="emailId"
              label="Email"
              onChange={handleInputChange}
              name="emailId"
              value={formData.emailId}
              autoFocus
            />
            {submitted && errors.emailId && (
              <STyledFieldError>{errors.emailId}</STyledFieldError>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              onChange={handleInputChange}
              name="password"
              label="Password"
              type="password"
              value={formData.password}
              id="password"
            />
            {submitted && errors.password && (
              <STyledFieldError>{errors.password}</STyledFieldError>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 4, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

export default Login;
