import React, { useEffect, useState } from "react";
import { QueryBuilder } from "react-querybuilder";
import "./style.scss";
import "react-querybuilder/dist/query-builder.scss";
import useApi from "../../hooks/useApi";
import { createRule, getRuleOperators, updateRule } from "../../services";
import Button from "@mui/material/Button";
import { toastInstance } from "../../utils/toastInstance";
import { StyledDialogActions } from "../dialog/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  setOperatorsAndFields,
  setSelectedRule,
} from "../../redux/features/rulesSlice";
import Spinner from "../spinner";
import CustomValueEditor from "./CustomValueEditor";
import { standardOperators, translations } from "../../constants/rulesConstants";

function RulesBuilder(props) {
  const { isEditable } = props;
  const dispatch = useDispatch();
  const _operatorsAndFields = useSelector(
    (state) => state.rules?.operatorsAndFields
  );
  const { operators, fields } = _operatorsAndFields || {};
  const _selectedRule = useSelector((state) => state.rules?.selectedRule);
  const [makeApiCall, loading] = useApi();
  const [query, setQuery] = useState(
    (_selectedRule?.config && JSON.parse(_selectedRule?.config)) || null
  );

  const fetchOperators = async () => {
    const res = await makeApiCall(getRuleOperators);
    const customOperators = res?.data?.operatorList.map((i) => {
      return { name: i.operator, label: i.operator };
    });
    const fields = res?.data?.entityId.map((i) => {
      return { name: i, label: i };
    });
    const operators = [...standardOperators, ...customOperators];
    dispatch(
      setOperatorsAndFields({
        operators,
        fields,
        operatorMetaData: res?.data?.operatorList,
      })
    );
  };

  useEffect(() => {
    fetchOperators();
  }, []);

  const handleReset = () => {
    setQuery(null);
  };

  const handleSubmit = async () => {
    const formattedQuery = JSON.stringify(query);
    if (_selectedRule?.ruleId) {
      const res = await makeApiCall(updateRule, _selectedRule?.ruleId, {
        config: formattedQuery,
      });
      if (res?.status === 200) {
        toastInstance("success", "Rule updated successfully!");
      }
    } else {
      const ruleData = { ..._selectedRule, config: formattedQuery };
      const res = await makeApiCall(createRule, ruleData);
      if (res?.status === 200) {
        dispatch(setSelectedRule({ ...ruleData, ruleId: res.data }));
        toastInstance("success", "Rule created successfully!");
      }
    }
  };

  const handleQueryChange = (q) => {
    setQuery(q);
  };
  return (
    <>
      {loading && <Spinner />}
      {_operatorsAndFields && (
        <QueryBuilder
          fields={fields}
          query={query || null}
          operators={operators}
          onQueryChange={handleQueryChange}
          controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
          translations={translations}
          resetOnOperatorChange
          controlElements={{
            valueEditor: CustomValueEditor,
          }}
        />
      )}
      {isEditable && (
        <StyledDialogActions style={{ marginTop: "15px" }}>
          <Button variant="outlined" onClick={handleReset}>
            Reset
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            Save
          </Button>
        </StyledDialogActions>
      )}
    </>
  );
}

export default RulesBuilder;
