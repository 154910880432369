import styled from "styled-components";
import Box from "@mui/material/Box";

export const StyledBackButton = styled.button`
  margin-left: auto;
  background-color: #fff;
  border: none;
  margin-right: 10px;
  cursor: pointer;
`;

export const StyledBox = styled(Box)`
  width: 100%;
  background: #fff;
  padding-bottom: 15px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const StyledButton = styled.button`
  color: #3965ff;
  text-align: center;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  border-radius: 6px;
  border: 2px solid #3965ff;
  background: #f4f7fe;
  height: 28px;
  width: 75px;
  text-transform: capitalize;
  &:hover {
    border: 2px solid #3965ff;
    background-color: #3965ff;
    color: #fff;
  }
`;

export const StyledIconButtonWrapper = styled.section`
  height: 42px;
  width: 68px;
  border-radius: 10px;
  background-color: #f4f7fe;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
`;

export const StyledTableMultiRow = styled.section`
  display: flex;
  flex-direction: column;
`;

export const StyledTableActionCell = styled.section`
  display: flex;
  gap: 10px;
`;
export const STyledFieldError = styled.span`
  color: #bd041a;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

export const StyledTooltipLink = styled.span`
  color: #A3AED0;
  font-size: 12px;
  font-style: italic;
  &:hover{
    text-decoration: underline;
  }
`;
