import React from "react";
import { StyledIconButtonWrapper } from "../../common-styles";
import SVGLoader from "../../components/svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import routesConfig from "../../constants/routeConfig";
import { useNavigate } from "react-router-dom";
import { removeSelectedRule } from "../../redux/features/rulesSlice";
import { useDispatch } from "react-redux";

function AddNewRuleButton() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <StyledIconButtonWrapper
        onClick={() => {
          dispatch(removeSelectedRule());
          navigate(routesConfig.RULES_CONFIGURATION, {
            state: { type: "add" },
          });
        }}
      >
        <SVGLoader icon={iconLibrary.PLUS_ICON} />
      </StyledIconButtonWrapper>
    </>
  );
}

export default AddNewRuleButton;
