import React, { useState } from "react";
import { StyledIconButtonWrapper } from "../../common-styles";
import SVGLoader from "../../components/svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import AddGroupDialog from "../dialog/AddorEditGroup";

function AddNewGroupButton(props) {
  const [showAddGroupDialog, setShowAddGroupDialog] = useState(false);
  const openAddGroupDialog = () => {
    setShowAddGroupDialog(true);
  };

  const closeAddGroupDialog = () => {
    setShowAddGroupDialog(false);
  };
  return (
    <>
      {showAddGroupDialog && (
        <AddGroupDialog
          handleClose={closeAddGroupDialog}
            updateList={props.updateList}
        />
      )}
      <StyledIconButtonWrapper onClick={openAddGroupDialog}>
        <SVGLoader icon={iconLibrary.PLUS_ICON} />
      </StyledIconButtonWrapper>
    </>
  );
}

export default AddNewGroupButton;
