import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme";
import "react-toastify/dist/ReactToastify.css";
import store from "./redux/app/store";
import { Provider } from "react-redux";
import ErrorBoundary from "./components/error-boundary";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ErrorBoundary>
          <BrowserRouter>
            <ThemeProvider theme={theme.light}>
              <AppRoutes />
            </ThemeProvider>
          </BrowserRouter>
        </ErrorBoundary>
      </Provider>
    </div>
  );
}

export default App;
