import styled from "styled-components";
import Paper from "@mui/material/Paper";

export const StyledPaper = styled(Paper)`
  padding: 20px;
  .soft-cards {
    display: flex;
    gap: 30px;
  }
  .MuiTableContainer-root{
    box-shadow: none;
  }
`;

export const StyledDashboardLayout = styled.section`
  display: flex;
  flex-direction: column;
  .dashboard-section{
    display: grid;
    grid-column-gap: 25px;
    margin-bottom: 25px;
  }
  .section1 {
    grid-template-columns: repeat(4, 1fr);
  }
  .section2 {
    grid-template-columns: 7fr 5fr;
  }
  .section3 {
    grid-template-columns: 5fr 7fr;
  }
  .section4 {
    grid-template-columns: 1fr;
  }
  .section5 {
    grid-template-columns: 1fr;
  }

`;

export const StyledContentPaper = styled(Paper)`
  height: 420px;
  padding: 25px 0;
  .MuiTableContainer-root {
    box-shadow: none;
  }
`;

export const StyledContentHeader = styled.h4`
  color: #05004e;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 15px;
  margin-left: 15px;
`;

export const StyledDatePickerWrapper = styled.section`
  display: flex;
  margin-left: auto;
  gap: 10px;
  align-items: flex-end;
  label{
    font-size: 12px;
  }
  button{
    height: 36px;
    width: 48px;
    border-radius: 10px;
    border: 2px solid #e0e5f2 ;
    background-color: #f4f7fe;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`