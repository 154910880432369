import React from "react";
import { StyledPaper, StyledReportForm } from "./styles";
import Button from "@mui/material/Button";
import TextField from "../../components/form-elements/text-field";
import DropdownField from "../../components/form-elements/drop-down";
import { reportTypes } from "../../constants/genericConstants";
import { useForm } from "react-hook-form";
import useApi from "../../hooks/useApi";
import { requestReports } from "../../services";
import { toastInstance } from "../../utils/toastInstance";
import Spinner from "../../components/spinner";

function Reports() {
  const form = useForm();
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;
  const [makeApiCall, loading] = useApi();

  const onSubmit = async (data) => {
    const payload = {
      toTime: data.toTime.replace("T", " ").concat(":00"),
      fromTime: data.fromTime.replace("T", " ").concat(":00"),
    };
    delete payload["report"];
    const res = await makeApiCall(requestReports, data.report, payload);
    if (res?.status === 200) {
      toastInstance("success", "Report request has been submitted successfully! Please check the download after sometime");
    }
  };

  return (
    <StyledPaper>
      {loading && <Spinner/>}
      <h1>Reports</h1>
      <StyledReportForm>
        <section className="form-body">
          <TextField
            label="Start Date"
            type="datetime-local"
            name="fromTime"
            id="fromTime"
            validation={register("fromTime", {
              required: "Start Date is required",
            })}
            errorMessage={errors.fromTime?.message}
          />
          <TextField
            label="End Date"
            type="datetime-local"
            name="toTime"
            id="toTime"
            validation={register("toTime", {
              required: "End Date is required",
            })}
            errorMessage={errors.toTime?.message}
          />
          <DropdownField
            label="Select Report"
            name="report"
            id="report"
            options={reportTypes}
            validation={register("report", {
              required: "Report is required",
            })}
            errorMessage={errors.report?.message}
          />
        </section>
        <br />
        <section className="form-actions">
          <Button variant="contained" onClick={handleSubmit(onSubmit)}>Apply</Button>
        </section>
      </StyledReportForm>
    </StyledPaper>
  );
}

export default Reports;
