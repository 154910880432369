import styled from "styled-components";
import Paper from "@mui/material/Paper";

export const StyledPaper = styled(Paper)`
  padding: 20px;
  h1 {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    margin-bottom: 20px;
  }
`;

export const StyledReportForm = styled.section`
  .form-body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 25px;
  }
  .form-actions{
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
