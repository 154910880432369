import {
  GET_TRANSACTIONS,
  CREAT_USER,
  UPDATE_USER,
  GET_USERS,
  DELETE_USER,
  GET_LOCATIONS,
  LOGIN_USER,
  GET_TRANSACTION,
  USER_INFO,
  GET_RULES,
  GET_RULE_DETAILS,
  GET_RULE_OPERATORS,
  UPDATE_RULE,
  CREATE_RULE,
  DELETE_RULE,
  CREATE_LIST,
  CREATE_RISK,
  GET_RISKS,
  GET_LISTS,
  UPDATE_RISK,
  DELETE_RISK,
  UPDATE_LIST,
  CREATE_GROUP,
  GET_GROUPS,
  DELETE_GROUP,
  UPDATE_GROUP,
  DELETE_LIST,
  DASHBOARD,
  UPDATE_TRANSACTION,
  TRIGGERED_RULES,
  SEARCH_ANALYTICS,
  SEARCH_TRANSACTIONS,
  SEARCH_RULES,
  SEARCH_USERS,
  SEARCH_LISTS,
  SEARCH_GROUPS,
  REQUEST_REPORT,
  REPORT_STATS,
  DOWNLOAD_REPORT,
  LIST_UPLOAD,
  GROUP_UPLOAD,
  FIELD_TYPES,
  GET_COMPANIES,
  SET_COMPANY,
  ADD_COMPANY,
  GENERATE_KEY,
  FETCH_KEY,
  GET_COMPANY_DETAILS,
  UPDATE_COMPANY_DETAILS,
} from "../utils/api";
import axiosInstance from "../utils/axios";

export const getTransactions = async (payload) => {
  return axiosInstance.post(GET_TRANSACTIONS, payload);
};

export const getTransactionDetails = async (payload) => {
  return axiosInstance.get(`${GET_TRANSACTION}/${payload}`);
};

export const createUser = async (payload) => {
  return axiosInstance.post(CREAT_USER, payload);
};

export const updateUser = async (payload, id) => {
  return axiosInstance.put(`${UPDATE_USER}/${id}`, payload);
};

export const getUsers = async (payload) => {
  return axiosInstance.post(GET_USERS, payload);
};

export const deleteUser = async (id) => {
  return axiosInstance.delete(`${DELETE_USER}/${id}`);
};

export const getLocations = async (payload) => {
  return axiosInstance.get(`${GET_LOCATIONS}/${payload}`);
};

export const loginUser = async (payload) => {
  return axiosInstance.post(LOGIN_USER, payload);
};

export const getUserInfo = async () => {
  return axiosInstance.get(USER_INFO);
};

export const getRules = async (limit = 5, offset = 0) => {
  return axiosInstance.get(`${GET_RULES}/${limit}/${offset}`);
};

export const getRuleDetails = async (id) => {
  return axiosInstance.get(`${GET_RULE_DETAILS}/${id}`);
};

export const getRuleOperators = async () => {
  return axiosInstance.get(GET_RULE_OPERATORS);
};

export const updateRule = async (id, payload) => {
  return axiosInstance.put(`${UPDATE_RULE}/${id}`, payload);
};

export const createRule = async (payload) => {
  return axiosInstance.post(CREATE_RULE, payload);
};

export const deleteRule = async (id) => {
  return axiosInstance.delete(`${DELETE_RULE}/${id}`);
};

export const createList = async (payload) => {
  return axiosInstance.post(CREATE_LIST, payload);
};

export const updateLists = async (id, payload) => {
  return axiosInstance.put(`${UPDATE_LIST}/${id}`, payload);
};

export const getLists = async (limit = 5, offset = 0) => {
  return axiosInstance.get(`${GET_LISTS}/${limit}/${offset}`);
};

export const deleteList = async (id) => {
  return axiosInstance.delete(`${DELETE_LIST}/${id}`);
};

export const createRisk = async (payload) => {
  return axiosInstance.post(CREATE_RISK, payload);
};

export const getRisks = async (limit = 5, offset = 0) => {
  return axiosInstance.get(`${GET_RISKS}/${limit}/${offset}`);
};

export const updateRisk = async (id, payload) => {
  return axiosInstance.put(`${UPDATE_RISK}/${id}`, payload);
};

export const deleteRisk = async (id) => {
  return axiosInstance.delete(`${DELETE_RISK}/${id}`);
};

export const createGroup = async (payload) => {
  return axiosInstance.post(CREATE_GROUP, payload);
};

export const getGroups = async (limit = 5, offset = 0) => {
  return axiosInstance.get(`${GET_GROUPS}/${limit}/${offset}`);
};

export const updateGroup = async (id, payload) => {
  return axiosInstance.put(`${UPDATE_GROUP}/${id}`, payload);
};

export const deleteGroup = async (id) => {
  return axiosInstance.delete(`${DELETE_GROUP}/${id}`);
};

export const getDashboardData = async (dashletName, payload) => {
  return axiosInstance.post(`${DASHBOARD}/${dashletName}`, payload);
};

export const updateTransaction = async (payload) => {
  return axiosInstance.put(`${UPDATE_TRANSACTION}`, payload);
};

export const getTriggeredRules = async (payload) => {
  return axiosInstance.get(`${TRIGGERED_RULES}/${payload}`);
};

export const getAnalytics = async (payload) => {
  return axiosInstance.post(`${SEARCH_ANALYTICS}`, payload);
};

export const searchTransactions = async (payload) => {
  return axiosInstance.post(`${SEARCH_TRANSACTIONS}`, payload);
};

export const searchRules = async (payload) => {
  return axiosInstance.post(`${SEARCH_RULES}`, payload);
};

export const searchUsers = async (payload) => {
  return axiosInstance.post(`${SEARCH_USERS}`, payload);
};

export const searchLists = async (payload) => {
  return axiosInstance.post(`${SEARCH_LISTS}`, payload);
};

export const searchGroups = async (payload) => {
  return axiosInstance.post(`${SEARCH_GROUPS}`, payload);
};

export const requestReports = async (reportName, payload) => {
  return axiosInstance.post(`${REQUEST_REPORT}/${reportName}`, payload);
};

export const getReportStats = async () => {
  return axiosInstance.get(`${REPORT_STATS}`);
};

export const downloadReport = async (reportName) => {
  return axiosInstance.get(`${DOWNLOAD_REPORT}/${reportName}`);
};

export const uploadLists = async (fileData) => {
  return axiosInstance.post(`${LIST_UPLOAD}`, fileData);
};

export const uploadGroups = async (fileData) => {
  return axiosInstance.post(`${GROUP_UPLOAD}`, fileData);
};

export const getFieldTypes = async () => {
  return axiosInstance.get(`${FIELD_TYPES}`);
};

export const getCompanies = async () => {
  return axiosInstance.get(`${GET_COMPANIES}`);
};

export const setCompany = async (payload) => {
  return axiosInstance.get(`${SET_COMPANY}/${payload}`);
};

export const addCompany = async (payload) => {
  return axiosInstance.post(`${ADD_COMPANY}`, payload);
};

export const generateApiKey = async (payload) => {
  return axiosInstance.post(`${GENERATE_KEY}`, payload);
};

export const fetchKey = async () => {
  return axiosInstance.get(`${FETCH_KEY}`);
};

export const getCompanyDetails = async (orgId) => {
  return axiosInstance.get(`${GET_COMPANY_DETAILS}/${orgId}`);
};

export const updateCompanyDetails = async (orgId, payload) => {
  return axiosInstance.put(`${UPDATE_COMPANY_DETAILS}/${orgId}`, payload);
};