import React, { useState } from "react";
import { StyledIconButtonWrapper } from "../../common-styles";
import SVGLoader from "../../components/svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import AddList from "../dialog/AddorEditList";

function AddNewListButton(props) {
  const [showAddListDialog, setShowAddListDialog] = useState(false);
  const openAddListDialog = () => {
    setShowAddListDialog(true);
  };

  const closeAddGroupDialog = () => {
    setShowAddListDialog(false);
  };
  return (
    <>
      {showAddListDialog && (
        <AddList
          handleClose={closeAddGroupDialog}
            updateList={props.updateList}
        />
      )}
      <StyledIconButtonWrapper onClick={openAddListDialog}>
        <SVGLoader icon={iconLibrary.PLUS_ICON} />
      </StyledIconButtonWrapper>
    </>
  );
}

export default AddNewListButton;
