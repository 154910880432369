import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "../form-elements/text-field";
import {
  StyledDialogTitle,
  StyledDialogActions,
  StyledDialogContent,
} from "./styles";
import DropdownField from "../form-elements/drop-down";
import RangeInput from "../form-elements/range-field";
import { transactionTypeList } from "../../constants/genericConstants";
import SVGLoader from "../svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import { toastInstance } from "../../utils/toastInstance";
import { isObjectEmpty } from "../../utils/commonUtils";

function TransactionsDialogBox(props) {
  const { handleClose, updateList } = props;
  const [form, setForm] = useState({});

  const handleChange = (e) => {
    const fieldName = e.target.name;
    let fieldValue = e.target.value;
    if (fieldName === "startDate" || fieldName === "endDate") {
      const convertedDateTime = fieldValue.replace("T", " ");
      fieldValue = convertedDateTime + ":00";
    }
    setForm({ ...form, [fieldName]: fieldValue });
  };

  const isValidForm = () => {
    if(isObjectEmpty(form)){
      toastInstance('error', 'Please provide filter conditions!')
      return false
    }
    return true
  }

  const handleSubmit = async () => {
    try {
      if (isValidForm()) {
        const searchCriteria = Object.keys(form).map((i) => {
          return {
            fieldName: i,
            value: i === "deviceMatchScore" ? form[i] * 0.01 : form[i],
          };
        });
        const payload = {
          searchCriteria,
          pageSize: 10,
          startIndex: 0,
        };
        updateList(payload);
        handleClose();
      }
    } catch (error) {
      toastInstance("error", "Something went wrong!");
    }
  };

  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="lg">
      <StyledDialogTitle>
        <section className="dialog-header-content">
          <span>Filter Transactions</span>
          <div onClick={handleClose}>
            <SVGLoader icon={iconLibrary.CANCEL_ICON} />
          </div>
        </section>
      </StyledDialogTitle>
      <StyledDialogContent>
        <TextField
          label="Start Date"
          type="datetime-local"
          name="startDate"
          onChange={handleChange}
        />
        <TextField
          label="End Date"
          type="datetime-local"
          name="endDate"
          onChange={handleChange}
        />
        <TextField label="User ID" name="userId" onChange={handleChange} />
        <TextField label="Devie ID" name="deviceId" onChange={handleChange} />
        <DropdownField label="Select List" name="list" onChange={handleChange} options={transactionTypeList}/>
        <DropdownField
          label="Select Location"
          name="location"
          onChange={handleChange}
        />
        <RangeInput
          label="Select Device Match Score"
          name="deviceMatchScore"
          value = {form.deviceMatchScore || 0}
          onChange={handleChange}
        />
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Back
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Apply
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
}

export default TransactionsDialogBox;
