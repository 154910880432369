import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fieldTypes: [],
};

const fieldTypeSlice = createSlice({
  name: "fieldType",
  initialState,
  reducers: {
    setFieldTypes: (state, action) => {
      state.fieldTypes = action.payload;
    },
  },
});

export const { setFieldTypes } = fieldTypeSlice.actions;

export default fieldTypeSlice.reducer;
