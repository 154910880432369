export const standardOperators = [
  { name: "string_equals", label: "Equal to" },
  { name: "not_string_equals", label: "Not equal to" },
  {
    name: "string_equals_case_insensitive",
    label: "Equal to (Case insensitive)",
  },
  { name: "greater_than", label: "Greater than" },
  { name: "greater_than_or_equal", label: "Greater than or equal" },
  { name: "less_than", label: "Less than" },
  { name: "less_than_or_equal", label: "Less than or equal" },
  { name: "in", label: "In" },
  { name: "not_in", label: "Not in" },
  { name: "regex", label: "Regex" },
  { name: "contains", label: "Contains" },
  { name: "not_contains", label: "Not Contains" },
  { name: "belongs_to_group", label: "Belongs to group" },
  { name: "not_belongs_to_group", label: "Does not belongs to group" },
];

export const translations = {
  addGroup: {
    label: "+  Group",
    title: "Add group",
  },
  addRule: {
    label: "+",
    title: "Add rule",
  },
};
