import React from "react";
import { StyledInputWrapper } from "./styles";

function TextField(props) {
  const {
    label,
    type,
    name,
    onChange,
    value,
    readOnly,
    list,
    validation,
    errorMessage,
    refName,
    defValue
  } = props;

  return (
    <StyledInputWrapper>
      <label>{label}</label>
      <input
        type={type}
        ref={refName}
        name={name}
        onChange={onChange}
        value={value}
        defaultValue={defValue}
        readOnly={readOnly}
        list={list}
        {...validation}
        className={errorMessage && "with-error"}
      />
      {errorMessage && <span className="error-msg">{errorMessage}</span>}
    </StyledInputWrapper>
  );
}

export default TextField;
