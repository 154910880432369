import React from "react";
import { StyledCardWrapper } from "./styles";
import SVGLoader from "../svgLoader";

function SoftCard({ title, icon, data, cardBg, iconBg, dataKey }) {
  return (
    <StyledCardWrapper cardBg={cardBg} iconBg={iconBg}>
      <section className="card-info">
        <div className="icon-wrapper">
        <SVGLoader icon={icon} color={'#fff'}/>
        </div>
        <span>{data?.[dataKey] || 0}</span>
      </section>
      <p>{title}</p>
    </StyledCardWrapper>
  );
}

export default SoftCard;
