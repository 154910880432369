export const GET_USERS = "/user/get-users";
export const GET_TRANSACTIONS = "/get-transactions";
export const GET_TRANSACTION = "/get-transaction";
export const GET_RULES = "/rule/rules";
export const GET_RULE_DETAILS = "/rule";
export const GET_RULE_OPERATORS = "/rule/operators-fields";
export const UPDATE_RULE = "/rule";
export const DELETE_RULE = "/rule";
export const CREATE_RULE = "/rule/create-rule";
export const UPDATE_USER = "/user/update";
export const CREAT_USER = "/user/create-user";
export const DELETE_USER = "/user";
export const FILTER_USERS = "/user/get-users";
export const FILTER_TRANSACTIONS = "/get-transactions";
export const GET_LOCATIONS = "/get-location";
export const LOGIN_USER = "/login";
export const USER_INFO = "/user/my-info";
export const GET_RISKS = "/risk";
export const CREATE_RISK = "/risk";
export const UPDATE_RISK = "/risk";
export const DELETE_RISK = "/risk";
export const GET_LISTS = "/list";
export const CREATE_LIST = "/list";
export const UPDATE_LIST = "/list";
export const DELETE_LIST = "/list";
export const GET_GROUPS = "/group";
export const CREATE_GROUP = "/group";
export const UPDATE_GROUP = "/group";
export const DELETE_GROUP = "/group";
export const DASHBOARD = "/dashboard";
export const UPDATE_TRANSACTION = "/list/update-classification";
export const TRIGGERED_RULES ="/get-ruleDetails";
export const SEARCH_ANALYTICS ="/analytics/search";
export const SEARCH_TRANSACTIONS ="/search/Transactions";
export const SEARCH_RULES ="/search/Rules";
export const SEARCH_USERS ="/search/User";
export const SEARCH_LISTS ="/search/List";
export const SEARCH_GROUPS ="/search/Groups";
export const REQUEST_REPORT ="/report/csv";
export const REPORT_STATS ="/report/report-stat";
export const DOWNLOAD_REPORT ="/report";
export const GROUP_UPLOAD ="/entityGroup/upload";
export const LIST_UPLOAD ="/classifications/upload";
export const FIELD_TYPES ="/list/field-types";
export const GET_COMPANIES ="/organization/all";
export const SET_COMPANY ="/organization";
export const ADD_COMPANY ="/organization";
export const GET_COMPANY_DETAILS ="/organization/details";
export const UPDATE_COMPANY_DETAILS ="/organization";
export const GENERATE_KEY ="/token/generate";
export const FETCH_KEY ="/token/getAllTokens";