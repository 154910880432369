import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "../form-elements/text-field";
import {
  StyledDialogTitle,
  StyledDialogActions,
  StyledDialogContent,
} from "./styles";
import DropdownField from "../form-elements/drop-down";
import { employeeTypeList, roleList } from "../../constants/genericConstants";
import SVGLoader from "../svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import { toastInstance } from "../../utils/toastInstance";
import { getLocations } from "../../services";
import DataList from "../form-elements/data-list";
import { isObjectEmpty } from "../../utils/commonUtils";

function FilterUser(props) {
  const { handleClose, updateList } = props;
  const [form, setForm] = useState({});
  const [location, setLocation] = useState("")
  const [locations, setLocations] = useState([])
  const handleChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    setForm({ ...form, [fieldName]: fieldValue });
    if(fieldName === "location"){
      setLocation(fieldValue)
    }
  };

  useEffect(()=>{
    searchLocations(location)
  },[location])
  
  const searchLocations = async(value)=> {
    if(value){
      try {
        const res = await getLocations(value)
        setLocations(res.data)
      } catch (error) {
        toastInstance('error','Unable to load the locations!')
      }
    }
  }

  const isValidForm = () => {
    if (isObjectEmpty(form)) {
      toastInstance('error',"Please provide the filter conditions!" )
      return false;
    }
    return true
  };

  const handleSubmit = async () => {
    try {
      if(isValidForm()){
        const searchCriteria = Object.keys(form).map((i) => {
          return {
            fieldName: i,
            value: form[i],
          };
        });
        const payload = {
          searchCriteria,
          pageSize: 10,
          startIndex: 0,
        };
        updateList(payload);
        handleClose();
      }
    } catch (error) {
      toastInstance("error", "Something went wrong!");
    }
  };

  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="lg">
      <StyledDialogTitle>
        <section className="dialog-header-content">
          <span>Flter User</span>
          <div onClick={handleClose}>
            <SVGLoader icon={iconLibrary.CANCEL_ICON} />
          </div>
        </section>
      </StyledDialogTitle>
      <StyledDialogContent>
        <TextField
          label="Username"
          onChange={handleChange}
          name="userName"
          value={form?.userName || ""}
        />
        <TextField
          label="Email ID"
          onChange={handleChange}
          name="emailId"
          value={form?.emailId || ""}
        />
        <DropdownField
          label="Role"
          onChange={handleChange}
          name="role"
          options={roleList}
          value={form?.role || ""}
        />
        <DataList
          label="Location"
          onChange={handleChange}
          options={locations}
          name="location"
          value={form?.location || ""}
          listid="location-list"
        />
        <TextField
          label="Profile Expiry"
          onChange={handleChange}
          type="date"
          name="profileExpiry"
          value={form?.profileExpiry || ""}
        />
        <DropdownField
          label="Employee Type"
          onChange={handleChange}
          name="empType"
          options={employeeTypeList}
          value={form?.empType || ""}
        />
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Back
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Apply
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
}

export default FilterUser;
