import React, { useState } from "react";
import UserFilterDialogBox from "../dialog/FilterUser";
import { StyledIconButtonWrapper } from "../../common-styles";
import iconLibrary from "../../constants/iconLibrary";
import SVGLoader from "../svgLoader";

const FilterUsers = ({ updateList }) => {
  const [showDialog, setShowDialog] = useState(false);
  const openDialog = () => {
    setShowDialog(true);
  };
  const closeDialog = () => {
    setShowDialog(false);
  };
  return (
    <>
      {showDialog && (
        <UserFilterDialogBox
          handleClose={closeDialog}
          updateList={updateList}
        />
      )}
      <StyledIconButtonWrapper onClick={openDialog}>
        <SVGLoader icon={iconLibrary.FILTER_ICON} />
      </StyledIconButtonWrapper>
    </>
  );
};

export default FilterUsers;
