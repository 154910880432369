import styled from "styled-components";

export const StyledCardWrapper = styled.section`
  border-radius: 16px;
  background: ${(props) => props.cardBg};
  display: flex;
  flex-direction: column;
  padding: 25px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);

  .card-info {
    display: flex;
    justify-content: space-between;
    span {
      color: #151d48;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; 
    }
    .icon-wrapper{
        width: 40px;
        height: 40px;
        background-color: ${(props) => props.iconBg};
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-bottom: 15px;
    }
  }
  p {
    font-family: DM Sans;
    color: #425166;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
`;
