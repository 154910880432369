import React, { useState } from "react";
import { StyledIconButtonWrapper } from "../../common-styles";
import SVGLoader from "../../components/svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import Uploads from "../dialog/Uploads";

const BulkUploadBtn = (props) => {
  const { parentComponent } = props;
  const [showDialog, setShowDialog] = useState(false);
  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };
  return (
    <>
      {showDialog && (
        <Uploads handleClose={closeDialog} parentComponent={parentComponent} />
      )}
      <StyledIconButtonWrapper onClick={openDialog}>
        <SVGLoader icon={iconLibrary.UPLOAD} />
      </StyledIconButtonWrapper>
    </>
  );
};

export default BulkUploadBtn;
