import { useState } from "react";
import { toastInstance } from "../utils/toastInstance";
import { useNavigate } from "react-router-dom";

const useApi = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const makeApiCall = async (service, payload, ...rest) => {
    try {
      setLoading(true);
      const res = await service(payload, ...rest);
      setLoading(false);
      return res;
    } catch (error) {
      setLoading(false);
      if (error.response?.status === 401) {
        toastInstance("error", error.response.data);
        localStorage.removeItem("authToken");
        navigate("/login");
      } else {
        const errMessage = error.response?.data?.message || "Something went wrong!"
        console.log(errMessage)
        toastInstance("error", errMessage);
      }
    }
  };

  return [makeApiCall, loading];
};

export default useApi;
