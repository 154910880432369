import styled from "styled-components";

export const StyledSearchFieldWrapper = styled.section`
  width: 400px;
  max-width: 100%;
  position: relative;
  input {
    width: 100%;
    background-color: #f4f7fe;
    border-radius: 49px;
    height: 42px;
    padding: 0 15px;
    border: 1px solid #f4f7fe;
    color: #8f9bba;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  button {
    background: white;
    border-radius: 50px;
    aspect-ratio: 1;
    height: 80%;
    position: absolute;
    top: 10%;
    right: 5px;
    border: none;
    cursor: pointer;
  }

  button:hover {
    opacity: 0.8;
  }
  input:focus {
    outline: 1px solid #2b3674;
  }
`;

export const StyledResetButton = styled.button`
  background-color: #f4f7fe;
  border-radius: 50px;
  aspect-ratio: 1;
  height: 42px;
  border: none;
  cursor: pointer;
  margin-left: 5px;
`;
