import styled from "styled-components";

export const StyledInputWrapper = styled.section`
  display: flex;
  flex-direction: column;
  input {
    height: 36px;
  }
  label {
    color: #2b3674;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    margin-bottom: 5px;
  }
`;
