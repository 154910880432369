import styled from "styled-components";
import Paper from "@mui/material/Paper";

const StyledPaper = styled(Paper)`
  padding: 20px;
  font-family: DM Sans;
  min-height: 50vh;
  h1 {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    margin-bottom: 20px;
  }
`;

const StyledFormSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 10px;
  button {
    width: 50%;
  }
`;

const StyledApiKeyContainer = styled.section`
  h3 {
    border: 2px solid rgb(2, 136, 209);
    border-radius: 4px;
    padding: 5px 10px;
    margin-bottom: 5px;
    word-wrap: break-word;
  }
  padding: 15px;
  background-color: rgb(229, 246, 253);
  max-width: 100vh;
  border-radius: 4px;
  .token-details{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    button{
      cursor: pointer;
    }
  }
`;

export { StyledPaper, StyledFormSection, StyledApiKeyContainer };
