import React from "react";
import { StyledInputWrapper } from "./styles";

function DropdownField(props) {
  const {
    label,
    type,
    name,
    onChange,
    options,
    value,
    validation,
    errorMessage,
  } = props;
  return (
    <StyledInputWrapper>
      <label>{label}</label>
      <select
        type={type}
        name={name}
        onChange={onChange}
        value={value}
        {...validation}
        className={errorMessage && "with-error"}
      >
        <option value="" selected disabled>
          Please select
        </option>
        {options?.length > 0 &&
          options.map((i) => (
            <option key={i} value={i}>
              {i}
            </option>
          ))}
      </select>
      {errorMessage && <span className="error-msg">{errorMessage}</span>}
    </StyledInputWrapper>
  );
}

export default DropdownField;
