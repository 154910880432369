import styled from "styled-components";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { styled as materialStyled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";

export const StyledListItemText = styled(ListItemText)`
  span {
    font-family: "DM Sans";
    color: ${(props) => props.$isActive ? props.theme.primary : props.theme.grey};
    font-size: 16px;
    font-style: normal;
    font-weight: ${(props) => props.$isActive ? 600 : 500};;
    line-height: 30px; /* 187.5% */
    letter-spacing: -0.32px;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const StyledBrand = styled.section`
  display: flex;
  align-items: center;
  margin-top: 20px;
  h2 {
    font-family: "Poppins";
    font-size: "26px";
    color: ${(props) => props.theme.primary};
    font-weight: 700;
    line-height: 100%; /* 26px */
    margin-left: 10px;
  }
  button{
    margin-left: 15px;
  }
  section{
    display: flex;
    align-items: center;
  }
  img{
    margin-top: -2px;
  }
`;

export const StyledShortBrand = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  gap: 5px;
`;

// modified material components

const drawerWidth = 240;

export const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  border: 0,
  overflowX: "hidden",
});

export const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  border: 0,
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = materialStyled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  background: "#fff",
  height: "90px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const Drawer = materialStyled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));