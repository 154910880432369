import styled from "styled-components";
import Box from "@mui/material/Box";

export const StyledLayoutContainer = styled(Box)`
  display: flex;
`;

export const StyledLayoutContentWrapper = styled(Box)`
  flex-grow: 1;
  background: #f0f5ff;
  min-height: 100vh;
`;

export const StyledOutletWrapper = styled.section`
  margin: 0 20px;
  margin-bottom: 40px;
`;
