export const tableHeaders = {
  USER_MANAGEMENT: [
    {
      id: 0,
      numeric: false,
      disablePadding: true,
      label: "User Name",
      key: "userName",
    },
    {
      id: 1,
      numeric: false,
      disablePadding: true,
      label: "User ID",
      key: "userId",
    },
    {
      id: 2,
      numeric: false,
      disablePadding: true,
      label: "Email ID",
      key: "emailId",
    },
    {
      id: 3,
      numeric: false,
      disablePadding: true,
      label: "Role",
      key: "role",
    },
    {
      id: 4,
      numeric: false,
      disablePadding: true,
      label: "Last Login",
      key: "lastLogin",
    },
    {
      id: 5,
      numeric: false,
      disablePadding: true,
      label: "User Status",
      key: "status",
    },
    {
      id: 6,
      numeric: false,
      disablePadding: true,
      label: "Action",
      key: null,
    },
  ],
  RULES: [
    {
      id: 0,
      numeric: false,
      disablePadding: true,
      label: "Rule ID",
    },
    {
      id: 1,
      numeric: false,
      disablePadding: true,
      label: "Rule Name",
    },
    {
      id: 2,
      numeric: false,
      disablePadding: true,
      label: "Mode",
    },
    {
      id: 3,
      numeric: false,
      disablePadding: true,
      label: "Rating",
    },
    {
      id: 4,
      numeric: false,
      disablePadding: true,
      label: "Status",
    },
    {
      id: 5,
      numeric: false,
      disablePadding: true,
      label: "Action",
    },
  ],
  CUSTOMER_ANALYTICS: [
    {
      id: 0,
      numeric: false,
      disablePadding: true,
      label: "Device ID",
    },
    {
      id: 1,
      numeric: false,
      disablePadding: true,
      label: "No. of Transactions",
    },
    {
      id: 2,
      numeric: false,
      disablePadding: true,
      label: "Unique Days",
    },
    {
      id: 3,
      numeric: false,
      disablePadding: true,
      label: "Is Favorite Device",
    },
    {
      id: 4,
      numeric: false,
      disablePadding: true,
      label: "All Locations",
    },
    {
      id: 5,
      numeric: false,
      disablePadding: true,
      label: "Favorite Locations",
    },
    {
      id: 6,
      numeric: false,
      disablePadding: true,
      label: "Favorite Time of Day",
    },
    {
      id: 7,
      numeric: false,
      disablePadding: true,
      label: "Network Preference",
    },
    {
      id: 8,
      numeric: false,
      disablePadding: true,
      label: "Average Transactions",
    },
    {
      id: 9,
      numeric: false,
      disablePadding: true,
      label: "Device Type",
    },
    {
      id: 10,
      numeric: false,
      disablePadding: true,
      label: "Alerts",
    },
  ],
  TRANSACTIONS: [
    {
      id: 0,
      numeric: false,
      disablePadding: true,
      label: "Timestamp",
      key: "transactionTimeStamp"
    },
    {
      id: 1,
      numeric: false,
      disablePadding: true,
      label: "Transaction ID",
      key:"transactionId"
    },
    {
      id: 2,
      numeric: false,
      disablePadding: true,
      label: "Device ID",
      key:"deviceId"
    },
    {
      id: 3,
      numeric: false,
      disablePadding: true,
      label: "User ID",
      key:"userId"
    },
    {
      id: 4,
      numeric: false,
      disablePadding: true,
      label: "Listed",
      key:"listed"
    },
    {
      id: 5,
      numeric: false,
      disablePadding: true,
      label: "Match Score",
      key:"deviceMatchScore"
    },
    {
      id: 6,
      numeric: false,
      disablePadding: true,
      label: "Risk Score",
      key:"riskScore"
    },
    {
      id: 7,
      numeric: false,
      disablePadding: true,
      label: "Risk Suggestion",
      key:"riskSuggestion"
    },
    {
      id: 8,
      numeric: false,
      disablePadding: true,
      label: "Action",
      key: null
    },
  ],
  RISK_CONFIGURATION: [
    {
      id: 0,
      numeric: true,
      disablePadding: true,
      label: "Minimum Risk Score",
      key: "minRiskScore"
    },
    {
      id: 1,
      numeric: false,
      disablePadding: true,
      label: "Risk Name",
      key: "riskName"
    },
    {
      id: 2,
      numeric: true,
      disablePadding: true,
      label: "Maximum Risk Score",
      key: "maxRiskScore"
    },
    {
      id: 3,
      numeric: false,
      disablePadding: true,
      label: "Activate Case",
      key: null
    },
    {
      id: 4,
      numeric: false,
      disablePadding: true,
      label: "Action",
      key: null
    },
  ],
  GROUPS: [
    {
      id: 0,
      numeric: false,
      disablePadding: true,
      label: "Data Field Type",
      key: "fieldType"
    },
    {
      id: 1,
      numeric: false,
      disablePadding: true,
      label: "Data Field Value",
      key: "fieldValue"
    },
    {
      id: 2,
      numeric: false,
      disablePadding: true,
      label: "Group Name",
      key: "groupName"
    },
    {
      id: 3,
      numeric: false,
      disablePadding: true,
      label: "Group Status",
      key: "groupStatus"
    },
    {
      id: 4,
      numeric: false,
      disablePadding: true,
      label: "Action",
      key: null
    },
  ],
  LISTS: [
    {
      id: 0,
      numeric: false,
      disablePadding: true,
      label: "Data Field Type",
      key: "fieldType"
    },
    {
      id: 1,
      numeric: false,
      disablePadding: true,
      label: "Data Field Value",
      key: "fieldValue"
    },
    {
      id: 2,
      numeric: false,
      disablePadding: true,
      label: "Classification",
      key: "classification"
    },
    {
      id: 3,
      numeric: false,
      disablePadding: true,
      label: "Action",
      key: null
    },
  ]
};
