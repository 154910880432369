import React, { useEffect, useState } from "react";
import RulesBuilder from "../../components/rules-builder";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import RuleDetails from "../../components/rule-details";
import { StyledBackButton, StyledBox } from "../../common-styles";
import SVGLoader from "../../components/svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import routesConfig from "../../constants/routeConfig";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import useApi from "../../hooks/useApi";
import { getRuleDetails } from "../../services";
import { toastInstance } from "../../utils/toastInstance";
import Spinner from "../../components/spinner";
import { useDispatch, useSelector } from "react-redux";
import { removeSelectedRule } from "../../redux/features/rulesSlice";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function RulesConfiguration() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [makeApiCall, loading] = useApi();
  const [ruleDetails, setRuleDetails] = useState(null);
  const { id: ruleId, type } = location.state;
  const _selectedRule = useSelector(state => state.rules?.selectedRule)
  const dispatch = useDispatch()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const fetchRuleDetails = async () => {
    if (ruleId) {
      const res = await makeApiCall(getRuleDetails, ruleId);
      setRuleDetails(res?.data);
    } else {
      type !== "add" && navigate(routesConfig.RULES);
    }
  };
  useEffect(() => {
    fetchRuleDetails();
    return () => {
      dispatch(removeSelectedRule())
    }
  }, []);

  return (
    <StyledBox>
      {loading && <Spinner />}
      {
        <>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Rule Details" {...a11yProps(0)} />
              <Tab
                label="Rule Configuration"
                {...a11yProps(1)}
                disabled={!_selectedRule}
              />
              <StyledBackButton
                onClick={() => navigate(routesConfig.RULES)}
              >
                <SVGLoader icon={iconLibrary.ARROW_CIRCLE_LEFT} />
              </StyledBackButton>
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <RuleDetails
              data={ruleDetails}
              proceedToConfig={() => handleChange(null, 1)}
              isEditable={type === "edit" || type === "add"}
              type={type}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1} style={{ padding: "15px" }}>
            <RulesBuilder
              isEditable={type === "edit" || type === "add"}
              ruleId={ruleDetails?.ruleId}
              ruleConfig={ruleDetails?.config}
              type={type}
            />
          </CustomTabPanel>
        </>
      }
    </StyledBox>
  );
}

export default RulesConfiguration;
