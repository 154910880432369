import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const rulesSlice = createSlice({
  name: "rules",
  initialState,
  reducers: {
    setOperatorsAndFields: (state, action) => {
      state.operatorsAndFields = action.payload;
    },
    setSelectedRule: (state, action) => {
      state.selectedRule = action.payload;
    },
    removeSelectedRule: (state) => {
      state.selectedRule = null;
    },
  },
});

export const { setOperatorsAndFields, setSelectedRule, removeSelectedRule } =
  rulesSlice.actions;

export default rulesSlice.reducer;
