import React, { useState, useEffect } from "react";
import {
  StyledLayout,
  StyledContentTitle,
  StyledBtnWrapper,
  StyledSubmitBtnWrapper,
} from "./styles";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import AddorEditCompany from "../../components/dialog/AddorEditCompany";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import useApi from "../../hooks/useApi";
import { getCompanies, setCompany } from "../../services";
import { toastInstance } from "../../utils/toastInstance";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCompanyName } from "../../redux/features/companySlice";

function SuperAdminConsole() {
  const [showModal, setShowModal] = useState(false);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [makeApiCall, loading] = useApi();
  const dispath = useDispatch()
  const navigate = useNavigate()
  const openModal = () => {
    setShowModal(true);
  };

  const fetchCompanies = async () => {
    const res = await makeApiCall(getCompanies);
    const companyList = res?.data.organizations.map((i, idx) => {
      return { label: i.second, value: i.first, id: idx };
    });
    setCompanies(companyList);
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleChange = (companyInfo) => { 
    setCompanyInfo(companyInfo)
  }

  const handleSubmit = async(e) => {
    e.preventDefault()
    if(!companyInfo){
      toastInstance('error',"Please select a company");
    }else{
      const res = await makeApiCall(setCompany,companyInfo.value);
      if(res.status === 200){
        localStorage.setItem("authToken", res.data.jwToken);
        dispath(setCompanyName(companyInfo.label))
        navigate("/")
      }
    }
  }

  return (
    <StyledLayout>
      {showModal && <AddorEditCompany handleClose={() => setShowModal(false)} updateData={fetchCompanies}/>}
      <StyledContentTitle>Welcome back Super Admin!</StyledContentTitle>
      <Container>
        <StyledBtnWrapper>
          <Button onClick={openModal} variant="contained">
            Add Company
          </Button>
        </StyledBtnWrapper>
        <Autocomplete
          disablePortal
          onChange={(event, newValue) => {
            handleChange(newValue);
          }}
          options={companies}
          renderInput={(params) => <TextField {...params} label="Company" />}
        />
        <StyledSubmitBtnWrapper>
          <Button onClick={handleSubmit} variant="contained">
            Submit
          </Button>
        </StyledSubmitBtnWrapper>
      </Container>
    </StyledLayout>
  );
}

export default SuperAdminConsole;
