import { useEffect, useState } from "react";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import menuList from "../../constants/sidebarContent";
import Box from "@mui/material/Box";
import iconLibrary from "../../constants/iconLibrary";
import {
  StyledLink,
  StyledListItemText,
  StyledBrand,
  StyledShortBrand,
  Drawer,
  DrawerHeader,
} from "./styles";
import SVGLoader from "../svgLoader";
import logo from "../../assets/logo/dwield.png";
import { useLocation } from "react-router-dom";
import { findActiveMenu } from "../../utils/commonUtils";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMenu } from "../../redux/features/menuSlice";

export default function Sidebar() {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { activeMenu } = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveMenu(findActiveMenu(location.pathname) || 0));
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuClick = (id) => {
    dispatch(setActiveMenu(id));
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <>
            {open ? (
              <StyledBrand>
                <section>
                  <img src={logo} alt="dwield logo" width={"30px"} />
                  <h2>Dwield.ai</h2>
                </section>
                <IconButton onClick={handleDrawerClose}>
                  <SVGLoader icon={iconLibrary.ARROW_CIRCLE_LEFT} />
                </IconButton>
              </StyledBrand>
            ) : (
              <StyledShortBrand>
                <img src={logo} alt="dwield logo" width={"30px"} />
                <IconButton aria-label="open drawer" onClick={handleDrawerOpen}>
                  <SVGLoader icon={iconLibrary.ARROW_CIRCLE_RIGHT} />
                </IconButton>
              </StyledShortBrand>
            )}
          </>
        </DrawerHeader>
        <Divider />
        <List>
          {menuList.map((i) => (
            <ListItem
              key={i.id}
              disablePadding
              sx={{ display: "block", marginBottom: "10px" }}
            >
              <Tooltip title={i.section} arrow placement="right">
                <StyledLink
                  to={i.routePath}
                  onClick={() => handleMenuClick(i.id)}
                  $isActive={activeMenu === i.id}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                      borderRight: activeMenu === i.id && "4px solid #4318FF",
                      boxSizing: "border-box",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <SVGLoader
                        icon={i.icon}
                        color={activeMenu === i.id && "#4318FF"}
                      />
                    </ListItemIcon>
                    <StyledListItemText
                      $isActive={i.id === activeMenu}
                      primary={i.section}
                      sx={{
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </StyledLink>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
