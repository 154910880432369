import { toast } from "react-toastify";

export const toastInstance = (type, message) => {
  return toast[type](message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    progress: undefined,
    theme: "colored",
    pauseOnHover: false
  });
};
