import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activeMenu: 0
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setActiveMenu: (state, action) => {
      state.activeMenu = action.payload;
    },
    removeActiveMenu: (state) => {
      state.activeMenu = -1;
    },
  },
});

export const { setActiveMenu, removeActiveMenu } =
  menuSlice.actions;

export default menuSlice.reducer;
