import { Box } from "@mui/material";
import styled from "styled-components";

export const StyledSpinnerBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  span{
    margin: 5px 0;
    font-family: 'DM Sans';
  }
`;
