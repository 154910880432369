import styled from "styled-components";

export const StyledFooter = styled.section`
  position: fixed;
  bottom: 0;
  height: 36px;
  display: flex;
  background: ${(props) => props.theme.secondary};
  color: ${(props) => props.theme.grey};
  width: 100%;
  align-items: center;
  justify-content: center;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.28px;
`;
