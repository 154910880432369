import React from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "../layout/Main";
import Dashboard from "../pages/dashboard";
import CustomerAnalytics from "../pages/customer-analytics";
import Transactions from "../pages/transactions";
import Reports from "../pages/reports";
import RulesDetails from "../pages/rules-details";
import RulesConfiguration from "../pages/rules-configuration";
import routesConfig from "../constants/routeConfig";
import UserManagement from "../pages/user-management";
import Login from "../pages/login";
import Toast from "../components/toast";
import ProtectedRoute from "./ProtectedRoute";
import Configurations from "../pages/configurations";
import Downloads from "../pages/downloads";
import SuperAdminConsole from "../pages/super-admin-console";
import IntegrationConsole from "../pages/integration-console";
// import NotFound from "../pages/not-found";

function AppRoutes() {
  return (
    <>
      <Toast />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<MainLayout />}>
            <Route path="/super-admin/console" element={<SuperAdminConsole />} />
            <Route index element={<Dashboard />} />
            <Route path={routesConfig.DASHBOARD} element={<Dashboard />} />
            <Route
              path={routesConfig.CUSTOMER_ANALYTICS}
              element={<CustomerAnalytics />}
            />
            <Route
              path={routesConfig.TRANSACTIONS}
              element={<Transactions />}
            />
            <Route path={routesConfig.REPORTS} element={<Reports />} />
            <Route
              path={routesConfig.RULES}
              element={<RulesDetails />}
            />
            <Route
              path={routesConfig.RULES_CONFIGURATION}
              element={<RulesConfiguration />}
            />
            <Route
              path={routesConfig.CONFIGURATIONS}
              element={<Configurations />}
            />
            <Route
              path={routesConfig.USER_MANAGEMENT}
              element={<UserManagement />}
            />
            <Route
              path={routesConfig.DOWNLOADS}
              element={<Downloads />}
            />
            <Route
              path={routesConfig.INTEGRATION_CONSOLE}
              element={<IntegrationConsole />}
            />
          </Route>
        </Route>
        {/* <Route path="*" element={<NotFound/>} /> */}
      </Routes>
    </>
  );
}

export default AppRoutes;
