import styled from "styled-components";

export const StyledInputWrapper = styled.section`
  display: flex;
  flex-direction: column;
  label {
    color: #2b3674;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    margin-bottom: 5px;
  }
  .error-msg{
    color: #bd041a;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    margin-top: 5px;
  }
`;

export const StyledColorPickerContainer = styled.section`
  display: flex;
  gap: 10px;
  height: 36px;
  border-radius: 6px;
  border: 2px solid #e0e5f2;
  padding: 0 5px;
  align-items: center;
  &:focus {
    border: 2px solid #2b3674;
    outline: none;
  }
  &.with-error {
    border: 2px solid #bd041a;
  }
  select {
    border: none;
    outline: none;
    font-family: DM Sans;
    color: #a3aed0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    letter-spacing: -0.28px;
    width: 100%;
  }
  option:hover {
    background-color: red;
  }
 
`;
