import React from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import DataLoadError from "../load-error-warning/DashboardLoadError";

function TransactionByRiskGraph(props) {
  const { data, isFetching } = props;

  return (
    <>
      <ResponsiveContainer>
        {data?.length > 0 && (
          <BarChart
            data={data}
            margin={{
              top: 15,
              right: 10,
              left: 0,
              bottom: 25,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis dataKey="name" axisLine={false} tickLine={false} />
            <YAxis axisLine={false} tickLine={false} />
            <Tooltip />
            <Bar
              barSize={50}
              dataKey="value"
              fill="#00E096"
              activeBar={<Rectangle fill="blue" stroke="blue" />}
            />
          </BarChart>
        )}
        {!isFetching && (data?.length === 0 || !data) && <DataLoadError />}
      </ResponsiveContainer>
    </>
  );
}

export default TransactionByRiskGraph;
