import styled from "styled-components";

export const StyledSVGwrapper = styled.section`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    path {
      fill: ${(prop) => prop.color};
    }
  }
`;
