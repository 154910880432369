import React, { useState } from "react";
import { StyledColorPickerContainer, StyledInputWrapper } from "./styles";
import { colors } from "../../../constants/genericConstants";

const ColorSelector = (props) => {
  const { label, validation, errorMessage, color } = props;
  return (
    <StyledInputWrapper>
      <label>{label}</label>
      <StyledColorPickerContainer className={errorMessage && "with-error"}>
        {color && (
          <div
            style={{ height: "24px", width: "24px", background: color }}
          ></div>
        )}
        <select {...validation}>
          <option value="">Select</option>
          {colors.map((i) => (
            <option key={i.hex} value={i.hex} style={{ color: i.hex }}>
              {i.name}
            </option>
          ))}
        </select>
      </StyledColorPickerContainer>
      {errorMessage && <span className="error-msg">{errorMessage}</span>}
    </StyledInputWrapper>
  );
};

export default ColorSelector;
