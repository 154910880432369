import styled from "styled-components";

export const StyledDownloadsTable = styled.table`
  background-color: #fff;
  width: 100%;
  border: 0px;
  border-collapse: collapse;
  thead {
    background-color: #e9edf7;
  }
  th {
    text-align: left;
    padding: 15px;
    color: #454545;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  td {
    padding: 15px;
    padding-right: 100px;
    color: #464e5f;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    a{
        text-decoration: underline;
        color: blue;
        cursor: pointer;
    }
  }
`;
