import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "../form-elements/text-field";
import { StyledDialogActions, StyledDialogContent } from "../dialog/styles";
import { toastInstance } from "../../utils/toastInstance";
import useApi from "../../hooks/useApi";
import { updateRule } from "../../services";
import { isObjectEmpty } from "../../utils/commonUtils";
import DropdownField from "../form-elements/drop-down";
import { ruleModeList, ruleStatusList } from "../../constants/genericConstants";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedRule } from "../../redux/features/rulesSlice";

let dirtyFields = {}

function RuleDetails(props) {
  const { isEditable, proceedToConfig, type } = props;
  const _selectedRule = useSelector(state => state.rules?.selectedRule)
  const dispatch = useDispatch()
  const [form, setForm] = useState(_selectedRule || null);
  const [makeApiCall, loading] = useApi();

  useEffect(() => {
    !form && setForm(_selectedRule);
  }, [_selectedRule]);

  const handleChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    dirtyFields[fieldName] = fieldValue
    setForm({ ...form, [fieldName]: fieldValue });
  };

  const validateForm = () => {
    if (isObjectEmpty(form)) {
      toastInstance("error", "Please enter the values!");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const isValid = validateForm();
    if (isValid) {
      switch (type) {
        case "edit":
          const updateRes = await makeApiCall(updateRule, form?.ruleId, dirtyFields);
          if (updateRes?.status === 200) {
            toastInstance("success", "Rule details updated successfully!");
            dispatch(setSelectedRule(form))
            proceedToConfig();
          }
          break;
        case "add":
          dispatch(setSelectedRule(form))
          proceedToConfig();
          break;
        default:
          break;
      }
    }
  };

  const handleReset = () => {
    setForm({});
  };

  const ruleDetailsCta = {
    add : 'Proceed to Rules Configuration',
    edit : 'Save & Proceed to Rules Configuration',
  }

  return (
    <>
      <StyledDialogContent>
        <TextField
          readOnly={!isEditable}
          label="Rule Name"
          onChange={handleChange}
          name="ruleName"
          value={form?.ruleName || ""}
        />
        <TextField
          readOnly={!isEditable}
          label="Rule Score"
          type="number"
          onChange={handleChange}
          name="score"
          value={form?.score || ""}
        />
        <DropdownField
          onChange={handleChange}
          options={ruleStatusList}
          readOnly={!isEditable}
          label="Status"
          name="status"
          value={form?.status || ""}
        />
        <DropdownField
          onChange={handleChange}
          options={ruleModeList}
          readOnly={!isEditable}
          label="Mode"
          name="mode"
          value={form?.mode || ""}
        />
        <TextField
          readOnly={!isEditable}
          label="Notes"
          onChange={handleChange}
          name="notes"
          value={form?.notes || ""}
        />
      </StyledDialogContent>
      {isEditable && (
        <StyledDialogActions>
          <Button variant="outlined" onClick={handleReset}>
            Reset
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            {ruleDetailsCta[type]}
          </Button>
        </StyledDialogActions>
      )}
    </>
  );
}

export default RuleDetails;
