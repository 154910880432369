const KEY_FIELDS = [
  {
    apiKey: "uuid",
    fieldName: "Transaction ID",
  },
  {
    apiKey: "score",
    fieldName: "Transaction Risk Score",
  },
  {
    apiKey: "suggestion",
    fieldName: "Risk Category",
  },
  {
    apiKey: "event_timestamp",
    fieldName: "Transaction time stamp",
  },
  {
    apiKey: "user_id",
    fieldName: "Email ID/Phone Number",
  },
  {
    apiKey: "time_of_the_day",
    fieldName: "Time of the day",
  },
  {
    apiKey: "user_history",
    fieldName: "User history",
  },
  {
    apiKey: "device_id",
    fieldName: "Device ID",
  },
  {
    apiKey: "has_lied_languages",
    fieldName: "Browser Languages Tampered",
  },
  {
    apiKey: "browser_name",
    fieldName: "Browser Name",
  },
  {
    apiKey: "has_lied_os",
    fieldName: "Browser OS Tampered",
  },
  {
    apiKey: "browser_platform",
    fieldName: "Browser Platform",
  },
  {
    apiKey: "has_lied_browser",
    fieldName: "Browser Tampered",
  },
  {
    apiKey: "browser_appVersion",
    fieldName: "Browser Version",
  },
  {
    apiKey: "city_name",
    fieldName: "City",
  },
  {
    apiKey: "window_cookieEnabled",
    fieldName: "Cookie Enabled",
  },
  {
    apiKey: "country_name",
    fieldName: "Country",
  },
  {
    apiKey: "device_category",
    fieldName: "Device Type ",
  },
  {
    apiKey: "browser_doNotTrack",
    fieldName: "Do Not Track",
  },
  {
    apiKey: "local_ip_address",
    fieldName: "IP Address",
  },
  {
    apiKey: "isp",
    fieldName: "ISP",
  },
  {
    apiKey: "latitude",
    fieldName: "Latitude",
  },
  {
    apiKey: "longitude",
    fieldName: "Longitude",
  },
  {
    apiKey: "browser_connection",
    fieldName: "Network",
  },
  {
    apiKey: "os_name",
    fieldName: "OS",
  },
  {
    apiKey: "os_version",
    fieldName: "OS version",
  },
  {
    apiKey: "has_lied_resolution",
    fieldName: "Screen Resolution Tampered",
  },
  {
    apiKey: "region_name",
    fieldName: "State",
  },
  {
    apiKey: "dateOffsetTimezone",
    fieldName: "Time Zone Offset",
  },
  {
    apiKey: "time_of_the_day",
    fieldName: "Time of the day",
  },
  {
    apiKey: "browser_maxTouchPoints",
    fieldName: "Touch points",
  },
  {
    apiKey: "browser_userAgent",
    fieldName: "User Agent",
  },
  {
    apiKey: "machine_id",
    fieldName: "Machine ID",
  },
  {
    apiKey: "comments",
    fieldName: "Comments",
  },
];

const ADDITIONAL_FIELDS = [
  {
    apiKey: "accept_documents",
    fieldName: "Acceptable Document Types ",
  },
  {
    apiKey: "accept_encoding",
    fieldName: "Acceptable Encoding Types ",
  },
  {
    apiKey: "accept_language",
    fieldName: "Acceptable Languages",
  },
  {
    apiKey: "adBlock",
    fieldName: "Adblock",
  },
  {
    apiKey: "audioFingerPrintFrequency",
    fieldName: "Audio Fingerprint Frequency",
  },
  {
    apiKey: "window_availHeight",
    fieldName: "Available Height ",
  },
  {
    apiKey: "window_availWidth",
    fieldName: "Available Width ",
  },
  {
    apiKey: "batteryInfo_level",
    fieldName: "Battery Level ",
  },
  {
    apiKey: "browser_appCodeName",
    fieldName: "Browser App Code Name ",
  },
  {
    apiKey: "browser_appName",
    fieldName: "Browser App Name ",
  },
  {
    apiKey: "browser_product",
    fieldName: "Browser Product",
  },
  {
    apiKey: "browser_productSub",
    fieldName: "Browser Product Sub ",
  },
  {
    apiKey: "browser_vendor",
    fieldName: "Browser Vendor",
  },
  {
    apiKey: "browser_vendorSub",
    fieldName: "Browser Vendor Sub",
  },
  {
    apiKey: "canvasId",
    fieldName: "Canvas ID",
  },
  {
    apiKey: "window_colorDepth",
    fieldName: "Color Depth",
  },
  {
    apiKey: "fonts_available",
    fieldName: "Fonts",
  },
  {
    apiKey: "browser_hardwareConcurrency",
    fieldName: "Hardware Concurrency",
  },
  {
    apiKey: "window_indexedDB",
    fieldName: "Indexed DB",
  },
  {
    apiKey: "window_sessionStorage",
    fieldName: "Is Session Storage Supported ",
  },
  {
    apiKey: "window_openDatabase",
    fieldName: "Is WebSQL Supported",
  },
  {
    apiKey: "window_localStorage",
    fieldName: "Local Storage Supported",
  },
  {
    apiKey: "window_devicePixelRatio",
    fieldName: "Pixel Ratio",
  },
  {
    apiKey: "browser_plugins",
    fieldName: "Plugins ",
  },
  {
    apiKey: "browser_language",
    fieldName: "System Language ",
  },
  {
    apiKey: "webgl",
    fieldName: "WebGL ",
  },
  {
    apiKey: "webglRenderer",
    fieldName: "WebGL Renderer ",
  },
  {
    apiKey: "webglVendor",
    fieldName: "WebGL Vendor",
  },
  {
    apiKey: "window_height",
    fieldName: "Window Height ",
  },
  {
    apiKey: "window_width",
    fieldName: "Window Width ",
  },
];

export { KEY_FIELDS, ADDITIONAL_FIELDS };
