import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "../form-elements/text-field";
import {
  StyledDialogTitle,
  StyledDialogActions,
  StyledDialogContent,
} from "./styles";
import DropdownField from "../form-elements/drop-down";
import { employeeTypeList, roleList, userStatusList } from "../../constants/genericConstants";
import SVGLoader from "../svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import { toastInstance } from "../../utils/toastInstance";
import { updateUser } from "../../services";
import { useSelector } from "react-redux";
import useApi from "../../hooks/useApi";

let dirtyFields = {}

function EditUser(props) {
  const { userData, handleClose } = props;
  const [form, setForm] = useState(userData || {});
  const { userDetails } = useSelector((state) => state?.user?.userInfo)|| {};
  const [makeApiCall, loading] = useApi()

  const handleChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    dirtyFields[fieldName] = fieldValue 
    setForm({ ...form, [fieldName]: fieldValue });
  };

  const validateForm = () => {
    if (Object.keys(form).length === 0) {
      toastInstance('error',"Please provide filter conditions!" )
      return false;
    }
    return true
  };

  const handleSubmit = async () => {
    const isValid = validateForm();
    if (isValid) {
      const res = await makeApiCall(updateUser, dirtyFields, userData.emailId);
      if (res.status === 200) {
        toastInstance("success", "User details updated successfully!");
        props.updateList();
        handleClose();
      }
    }
  };
  const handleReset = () => {
    setForm(userData);
  };
  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="lg">
      <StyledDialogTitle>
        <section className="dialog-header-content">
          <span>Edit User</span>
          <div onClick={handleClose}>
            <SVGLoader icon={iconLibrary.CANCEL_ICON} />
          </div>
        </section>
      </StyledDialogTitle>
      <StyledDialogContent>
        <TextField
          label="Username"
          onChange={handleChange}
          name="userName"
          value={form?.userName || ""}
        />
        <TextField
          label="Email ID"
          onChange={handleChange}
          name="emailId"
          readOnly
          value={form?.emailId || ""}
        />
        {userDetails?.role.includes("Admin") && (
          <DropdownField
            label="Role"
            onChange={handleChange}
            name="role"
            options={roleList}
            value={form?.role || ""}
          />
        )}
        <TextField
          label="Location"
          onChange={handleChange}
          name="location"
          value={form?.location || ""}
        />
        <TextField
          label="Profile Expiry"
          onChange={handleChange}
          type="date"
          name="profileExpiry"
          value={new Date(form?.profileExpiry).toISOString().slice(0, 10) || ""}
        />
        <DropdownField
          label="Employee Type"
          onChange={handleChange}
          name="empType"
          options={employeeTypeList}
          value={form?.empType || ""}
        />
        {userDetails?.role === "SuperAdmin" && (
          <DropdownField
            label="Active Status"
            onChange={handleChange}
            name="status"
            options={userStatusList}
            value={form?.status || ""}
          />
        )}
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={handleReset}>
          Reset
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Apply
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
}

export default EditUser;
