import React, { useState, useMemo, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableToolbar from "../../components/data-table/TableToolbar";
import TableHead from "../../components/data-table/TableHeader";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { StyledTableActionCell } from "../../common-styles";
import { tableHeaders } from "../../constants/tableConfig";
import {
  getComparator,
  stableSort,
} from "../../components/data-table/helper-functions";
import AddNewListButton from "../add-new-list";
import { deleteList, getFieldTypes, getLists, searchLists } from "../../services";
import useApi from "../../hooks/useApi";
import { toastInstance } from "../../utils/toastInstance";
import { useSelector, useDispatch } from "react-redux";
import AddorEditList from "../dialog/AddorEditList";
import Spinner from "../spinner";
import ViewmoreToolTip from "../view-more-tooltip";
import Pagination from "../data-table/Pagination";
import Confirmation from "../dialog/Confirmation";
import BulkUploadBtn from "../bulk-uploader-button";
import { removeSearchCriteria } from "../../redux/features/searchSlice";
import { setFieldTypes } from "../../redux/features/fieldTypeSlice";

const headCells = tableHeaders["LISTS"];

function Lists() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0)
  const [makeApiCall, loading] = useApi();
  const { userDetails } = useSelector((state) => state?.user?.userInfo) || {};
  const [selectedList, setSelectedList] = useState(null);
  const [showEditList, setShowEditList] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const { searchCriteria } = useSelector((state) => state.search);
  const dispatch = useDispatch()

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const visibleRows = useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, rows]
  );
  const fetchLists = async (payload = null, limit = 5, offset = 0) => {
    const res = await makeApiCall(getLists, limit, offset);
    if (res) {
      const { dwieldLists, totalRecords } = res?.data;
      setRows(dwieldLists);
      setTotalRecords(totalRecords)
    } else {
      toastInstance("error", "Something went wrong!");
    }
  };
  const searchListsData = async (
    searchPayload,
    pageSize = 5,
    startIndex = 0
  ) => {
    const payload = searchPayload
      ? { ...searchPayload, pageSize, startIndex }
      : {
          ...searchCriteria,
          pageSize,
          startIndex,
        };
    const res = await makeApiCall(searchLists, payload);
    if (res) {
      const { dwieldLists, totalRecords } = res?.data;
      setRows(dwieldLists);
      setTotalRecords(totalRecords)
    }
  };

  const fetchFieldTypes = async() => {
    const res = await makeApiCall(getFieldTypes);
    if (res) {
      dispatch(setFieldTypes(res?.data))
    }
  }

  useEffect(() => {
    fetchLists();
    fetchFieldTypes()
    return () => {
      dispatch(removeSearchCriteria())
    }
  }, []);

  const handleUpdate = (list) => {
    setSelectedList(list);
    setShowEditList(true);
  };

  const handleDelete = async (list) => {
    setSelectedList(list);
    setShowConfirm(true);
  };
  
  const formatFieldValues = (data) => {
    const dataList = data.split(",")
    if(dataList.length>2){
      return <ViewmoreToolTip fieldValues={dataList}/>
    }else{
      return data
    }
  }

  const confirmDelete = async () => {
    const res = await makeApiCall(deleteList, selectedList?.listId);
    if (res.status === 200) {
      toastInstance("success", "List successfully deleted!");
      setShowConfirm(false);
      fetchLists();
    }
  };

  return (
    <section>
      <Box sx={{ width: "100%" }}>
        {showConfirm && (
          <Confirmation
            handleClose={() => setShowConfirm(false)}
            handleConfirm={confirmDelete}
          />
        )}
        {loading && <Spinner />}
        {showEditList && (
          <AddorEditList
            handleClose={() => setShowEditList(false)}
            updateList={fetchLists}
            listData={selectedList}
            isEdit
          />
        )}
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableToolbar
            title={"Lists"}
            component={"LISTS"}
            fetchData={searchListsData}
            fetchAllData={fetchLists}
            compoentToRender={
              userDetails?.role.includes("Admin")  && (
                <>
                  <AddNewListButton updateList={fetchLists} />
                  <BulkUploadBtn parentComponent = {"Lists"}/>
                </>
              )
            }
          />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="Risk Configuration">
              <TableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length}
                headCells={headCells}
              />
              <TableBody>
                {visibleRows.length> 0 && visibleRows.map((row) => (
                  <TableRow hover key={row.listId} sx={{ cursor: "pointer" }}>
                    <TableCell align="left">{row.fieldType}</TableCell>
                    <TableCell align="left">
                      {formatFieldValues(row.fieldValue)}
                    </TableCell>
                    <TableCell align="left">{row.classification}</TableCell>
                    <TableCell align="left">
                      <StyledTableActionCell>
                        {(userDetails?.role.includes("Admin")  ||
                          userDetails?.role === "Editor") && (
                          <BorderColorOutlinedIcon
                            sx={{ color: "#3965FF" }}
                            onClick={() => handleUpdate(row)}
                          />
                        )}
                        {userDetails?.role.includes("Admin")  && (
                          <DeleteOutlinedIcon
                            sx={{ color: "#BD041A" }}
                            onClick={() => handleDelete(row)}
                          />
                        )}
                      </StyledTableActionCell>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalRecords}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            fetchData={fetchLists}
            searchData={searchListsData}
          />
        </Paper>
      </Box>
    </section>
  );
}

export default Lists;
