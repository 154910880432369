import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import {
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogActions,
  StyledCenterAlignedTextContainer,
} from "./styles";
import SVGLoader from "../svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import Button from "@mui/material/Button";
import { FileUploader } from "react-drag-drop-files";
import useApi from "../../hooks/useApi";
import { uploadGroups, uploadLists } from "../../services";
import { toastInstance } from "../../utils/toastInstance";
import Spinner from "../spinner";

const fileTypes = ["csv"];

const uploadsConfig = {
  Lists: {
    templateUri: "/templates/list_template.csv",
    service: uploadLists
  },
  Groups: {
    templateUri: "/templates/group_template.csv",
    service: uploadGroups
  },
};

function Uploads(props) {
  const { parentComponent, handleClose } = props;
  const [file, setFile] = useState(null);
  const [makeApiCall, loading] = useApi();
  const handleChange = (file) => {
    setFile(file);
  };

  const handleUpload = async () => {
    let formData = new FormData();
    formData.append("file", file);
    const res = await makeApiCall(uploadsConfig[parentComponent]?.service, formData);
    if (res?.status === 200) {
      toastInstance("success", `${parentComponent} Data submitted successfully!`);
      handleClose();
    }
  };

  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="md">
      <StyledDialogTitle>
        <section className="dialog-header-content">
          <span>{parentComponent} bulk uploads</span>
          <div onClick={handleClose}>
            <SVGLoader icon={iconLibrary.CANCEL_ICON} />
          </div>
        </section>
      </StyledDialogTitle>
      {loading && <Spinner/>}
      <StyledCenterAlignedTextContainer>
        <p>
          Click{" "}
          <a href={uploadsConfig[parentComponent]?.templateUri} download>
            here
          </a>{" "}
          to download the template
        </p>
      </StyledCenterAlignedTextContainer>
      <StyledDialogContent bulkuploader>
        <FileUploader
          classes="uploadBox"
          handleChange={handleChange}
          name="file"
          types={fileTypes}
        />
      </StyledDialogContent>
      <StyledCenterAlignedTextContainer>
        <p>{file ? `File name: ${file?.name}` : "No files uploaded yet"}</p>
      </StyledCenterAlignedTextContainer>
      <br />
      <StyledDialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button variant="contained" onClick={handleUpload}>
          Upload
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
}

export default Uploads;
