import styled from "styled-components";
import Paper from "@mui/material/Paper";

const StyledLayout = styled(Paper)`
  padding: 20px;
  font-family: DM Sans;
  min-height: 50vh;
`;

const StyledContentTitle = styled.h4`
  color: #05004e;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-bottom: 1rem;
`;

const StyledBtnWrapper = styled.section`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  width: 100%;
`;

const StyledSubmitBtnWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
`

export { StyledLayout, StyledContentTitle, StyledBtnWrapper, StyledSubmitBtnWrapper };
