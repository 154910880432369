const useCases = [
  "Account takeover",
  "Transaction Risk Analysis",
  "Payment Authentication",
  "Promotional Abuse",
  "Identity theft",
  "Bot Attack",
  "Fake/New Account Fraud",
];

const subscriptionTypes = ["Standard", "Premium", "Custom"];

const companySize = [
  "1-50",
  "50-100",
  "100-300",
  "300-500",
  "500-1000",
  "1000-5000",
  "5000-10000",
  "10000+",
];

const industries = [
  "Financial Institutions",
  "Fintech",
  "NBFC/Neobanks",
  "eCommerce ",
  "Healthcare",
  "Insurance",
  "Education",
  "Entertainment/Digital Content",
  "Gaming",
  "Legal/Governments",
  "Marketing /Advertising",
];

export { companySize, industries, useCases, subscriptionTypes };
