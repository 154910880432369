import styled from "styled-components";

export const StyledNavContainer = styled.nav`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
`;

export const StyledHeaderMenu = styled.section`
  height: 55px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #fff;
  box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
  display: flex;
  gap: 5px;
  justify-content: center;
  padding: 0 20px;
  align-items: center;
  margin-right: 20px;
`;

export const StyledCompanyTitle = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    margin-left: 20px;
    color: #05004e;
    font-size: 24px;
    font-family: Poppins;
  }
`;
