import React, { useState } from "react";
import { StyledIconButtonWrapper } from "../../common-styles";
import SVGLoader from "../../components/svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import AddRiskDialog from "../dialog/AddorEditRisk";

function AddNewRiskButton(props) {
  const [showAddRiskDialog, setShowAddRiskDialog] = useState(false);
  const openAddRiskDialog = () => {
    setShowAddRiskDialog(true);
  };

  const closeAddRiskDialog = () => {
    setShowAddRiskDialog(false);
  };

  return (
    <>
      {showAddRiskDialog && (
        <AddRiskDialog
          handleClose={closeAddRiskDialog}
          updateList={props.updateList}
        />
      )}
      <StyledIconButtonWrapper onClick={openAddRiskDialog}>
        <SVGLoader icon={iconLibrary.PLUS_ICON} />
      </StyledIconButtonWrapper>
    </>
  );
}

export default AddNewRiskButton;
