import React, { useState, useEffect, useRef } from "react";
import SoftCard from "../../components/soft-card";
import {
  StyledDashboardLayout,
  StyledPaper,
  StyledContentPaper,
  StyledContentHeader,
  StyledDatePickerWrapper,
} from "./styles";
import iconLibrary from "../../constants/iconLibrary";
import DeviceCategoryGraph from "../../components/dashboard-components/DeviceCategoryGraph";
import DeviceLocationTable from "../../components/dashboard-components/DeviceLocationTable";
import UserCountGraph from "../../components/dashboard-components/UserCountGraph";
import TopCustomersTable from "../../components/dashboard-components/TopCustomersTable";
// import ResponseTimeGraph from "../../components/dashboard-components/ResponseTimeGraph";
import TextField from "../../components/form-elements/text-field";
import useApi from "../../hooks/useApi";
import { dashletLibrary } from "../../constants/dashboardConstants";
import { getDashboardData } from "../../services";
import { toastInstance } from "../../utils/toastInstance";
import Spinner from "../../components/spinner";
import { startAndCurrentTimeStamp } from "../../utils/commonUtils";
import SVGLoader from "../../components/svgLoader";
import TransactionByRiskGraph from "../../components/dashboard-components/TransactionByRiskGraph";


const softCardConfig = [
  {
    title: "No of total devices",
    icon: iconLibrary.DEVICE,
    cardBg: "#f3e8ff",
    iconBg: "#BF83FF",
    dataKey: "totalDevices"
  },
  {
    title: "No of repeat devices",
    icon: iconLibrary.REPEAT_DEVICE,
    cardBg: "#FFF4DE",
    iconBg: "#FF947A",
    dataKey: "repeatDevices"
  },
  {
    title: "No of new devices",
    icon: iconLibrary.NEW_DEVICE,
    cardBg: "#DCFCE7",
    iconBg: "#3CD856",
    dataKey: "newDevices"
  },
  {
    title: "No of risky devices",
    icon: iconLibrary.BLOCKED_DEVICE,
    cardBg: "#FFE2E5",
    iconBg: "#FA5A7D",
    dataKey: "riskyDevices"
  },
];

const dashLetlists = Object.keys(dashletLibrary);

function Dashboard() {
  const timestampFilter = startAndCurrentTimeStamp();
  const startDateRef = useRef();
  const endDateRef = useRef();
  const [data, setData] = useState({});
  const [makeApiCall, loading] = useApi();

  const fetchData = async (start, end) => {
    const payload = {
      fromTime: start || timestampFilter.startOfToday,
      toTime: end || timestampFilter.currentTimeStamp,
    };
    const res = await Promise.allSettled(
      dashLetlists.map((i) =>
        makeApiCall(getDashboardData, dashletLibrary[i], payload)
      )
    );
    if (res?.length > 0) {
      const processedData = processDashboardData(res);
      setData(processedData);
    } else {
      toastInstance("error", "Something went wrong!");
    }
  };

  const processDashboardData = (data) => {
    const processedData = {};
    data.forEach((i, idx) => {
      if (i.status === "fulfilled") {
        processedData[dashLetlists[idx]] = i.value?.data;
      } else {
        processedData[dashLetlists[idx]] = null;
      }
    });
    return processedData;
  };

  const handleClick = () => {
    const fromDate = startDateRef?.current?.value?.replace("T", " ").concat(":00");
    const toDate = endDateRef?.current?.value?.replace("T", " ").concat(":00");
    fetchData(fromDate, toDate);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <StyledPaper>
      {loading && <Spinner />}
      <StyledDashboardLayout>
        <StyledDatePickerWrapper>
          <TextField
            refName={startDateRef}
            type="datetime-local"
            label="Start Date"
            defValue={timestampFilter.startOfToday}
          />
          <TextField
            refName={endDateRef}
            type="datetime-local"
            label="End Date"
            defValue={timestampFilter.currentTimeStamp}
          />
          <button onClick={handleClick}>
            <SVGLoader icon={iconLibrary.SEARCH} color={"#4318FF"} />
          </button>
        </StyledDatePickerWrapper>
        <StyledContentHeader>Devices Overview</StyledContentHeader>
        <section className="dashboard-section section1">
          {softCardConfig.map((i) => (
            <section key={i.title}>
              <SoftCard
                title={i.title}
                data={data?.["TODAYS_DEVICES"]}
                icon={i.icon}
                cardBg={i.cardBg}
                iconBg={i.iconBg}
                dataKey={i.dataKey}
              />
            </section>
          ))}
        </section>
        <section className="dashboard-section section2">
          <StyledContentPaper>
            <StyledContentHeader>Device category</StyledContentHeader>
            <DeviceCategoryGraph
              data={data?.["DEVICE_CATEGORY"]}
              isFetching={loading}
            />
          </StyledContentPaper>
          <StyledContentPaper>
            <StyledContentHeader>Devices by location</StyledContentHeader>
            <DeviceLocationTable
              data={data?.["DEVICE_BY_LOCATION"]}
              isFetching={loading}
            />
          </StyledContentPaper>
        </section>
        <section className="dashboard-section section3">
          <StyledContentPaper>
            <StyledContentHeader>Top customers</StyledContentHeader>
            <TopCustomersTable
              data={data?.["TOP_CUSTOMERS"]}
              isFetching={loading}
            />
          </StyledContentPaper>
          <StyledContentPaper>
            <StyledContentHeader>Users count</StyledContentHeader>
            <UserCountGraph data={data?.["USER_COUNT"]} isFetching={loading} />
          </StyledContentPaper>
        </section>
        <section className="dashboard-section section4">
          <StyledContentPaper>
            <StyledContentHeader>Transactions by risk</StyledContentHeader>
            <TransactionByRiskGraph
              data={data?.["TRANSACTION_BY_RISK"]}
              isFetching={loading}
            />
          </StyledContentPaper>
        </section>
        <section className="dashboard-section section5">
          <StyledContentPaper>
            <StyledContentHeader>Transactions by response time</StyledContentHeader>
            <TransactionByRiskGraph
              data={data?.["TRANSACTION_BY_RESPONSE_TIME"]}
              isFetching={loading}
            />
          </StyledContentPaper>
        </section>
      </StyledDashboardLayout>
    </StyledPaper>
  );
}

export default Dashboard;
