import axios from "axios";
import { toastInstance } from "./toastInstance";

const axiosInstance = axios.create({
  baseURL: `https://www.testweb.dwield.ai:8762/`,
  timeout: 10000,
  timeoutErrorMessage: "Request timeout.. Please try again!",
});

const attachToken = (req) => {
  let authToken = localStorage.getItem("authToken");
  if (authToken) {
    req.headers.Authorization = `Bearer ${authToken}`;
  }
  return req;
};

axiosInstance.interceptors.request.use(async (req) => {
  const modifiedReq = attachToken(req, true);
  return modifiedReq;
});

// axiosInstance.interceptors.response.use(
//   async (res) => res,
//   (error) => {
//     if (error.response.status === 401) {
//       toastInstance("error", error.response.data);
//       localStorage.removeItem("authToken");
//       setTimeout(() => {
//         window.location.href = "/login";
//       }, 1000);
//     }
//   }
// );

export default axiosInstance;
