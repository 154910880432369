import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  StyledDialogTitle,
  StyledDialogActions,
  StyledDialogContent,
} from "./styles";
import SVGLoader from "../svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import { toastInstance } from "../../utils/toastInstance";
import { createList, updateLists } from "../../services";
import useApi from "../../hooks/useApi";
import DropdownField from "../form-elements/drop-down";
import {
  listClassification,
} from "../../constants/genericConstants";
import { useForm } from "react-hook-form";
import TextArea from "../form-elements/text-area";
import Spinner from "../spinner";
import { emailRegex, getOnlyDirtyFields, ipAndIprangeregex } from "../../utils/commonUtils";
import { useSelector } from "react-redux";

function AddorEditList(props) {
  const { handleClose, isEdit, updateList, listData } = props;
  const form = useForm({
    defaultValues: {
      fieldType: listData?.fieldType || "",
      fieldValue: listData?.fieldValue || "",
      classification: listData?.classification || "",
    },
  });
  const [makeApiCall, loading] = useApi();

  const { register, handleSubmit, formState, getValues } = form;
  const { errors, dirtyFields } = formState;
  const { fieldTypes } = useSelector((state) => state.fieldTypes);

  const onSubmit = async (data) => {
    if (isEdit) {
      const updatedValues = getOnlyDirtyFields(dirtyFields, data)
      if(updatedValues?.["fieldValue"]){
        updatedValues.fieldType = data.fieldType
      }
      const res = updatedValues && await makeApiCall(updateLists, listData.listId, updatedValues);
      if (res?.status === 200) {
        toastInstance("success", "List updated successfully!");
        updateList();
        handleClose();
      }
    } else {
      const res = await makeApiCall(createList, data);
      if (res?.status === 201) {
        toastInstance("success", "List created successfully!");
        updateList();
        handleClose();
      }
    }
  };
  const title = isEdit ? "Edit List Details" : "Add New List Details";
  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="lg">
      {loading && <Spinner />}
      <StyledDialogTitle>
        <section className="dialog-header-content">
          <span>{title}</span>
          <div onClick={handleClose}>
            <SVGLoader icon={iconLibrary.CANCEL_ICON} />
          </div>
        </section>
      </StyledDialogTitle>
      <StyledDialogContent>
        <DropdownField
          label="Data Field Type"
          name="fieldType"
          id="fieldType"
          validation={register("fieldType", {
            required: "Field Type is required",
          })}
          options={fieldTypes}
          errorMessage={errors.fieldType?.message}
        />
        <TextArea
          label="Data Field Value"
          name="fieldValue"
          id="fieldValue"
          validation={register("fieldValue", {
            required: "Field Value is required",
            validate: (value) => {
              const fieldType = getValues('fieldType')
              if(fieldType === "emailId"){
                const emails = value.split(",");
                const allValid = emails.every((email) =>
                  emailRegex.test(email.trim())
                );
                return (
                  allValid ||
                  "Enter valid email ids"
                );
              }
              if(fieldType === "ip"){
                const ips = value.split(",");
                const isallValidIp = ips.every((ip) =>
                  ipAndIprangeregex.test(ip.trim())
                );
                return (
                  isallValidIp || "Enter valid IP or IP range"
                )
              }
            },
          })}
          errorMessage={errors.fieldValue?.message}
        />
        <DropdownField
          options={listClassification}
          label="Classification"
          name="classification"
          id="classification"
          validation={register("classification", {
            required: "Classification is required",
          })}
          errorMessage={errors.classification?.message}
        />
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Back
        </Button>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          Apply
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
}

export default AddorEditList;
