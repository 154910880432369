import React, { useState, useMemo, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableToolbar from "../../components/data-table/TableToolbar";
import Pagination from "../../components/data-table/Pagination";
import TableHead from "../../components/data-table/TableHeader";
import {
  getComparator,
  stableSort,
  generateEmptyRows,
} from "../../components/data-table/helper-functions";
import { StyledButton, StyledTableActionCell } from "../../common-styles";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { tableHeaders } from "../../constants/tableConfig";
import { useDispatch, useSelector } from "react-redux";
import AddNewRuleButton from "../../components/add-new-rule";
import RuleDetails from "../../components/rule-details";
import { useNavigate } from "react-router-dom";
import routesConfig from "../../constants/routeConfig";
import useApi from "../../hooks/useApi";
import { deleteRule, getRules, searchRules } from "../../services";
import { toastInstance } from "../../utils/toastInstance";
import Confirmation from "../../components/dialog/Confirmation";
import Spinner from "../../components/spinner";
import { setSelectedRule } from "../../redux/features/rulesSlice";
import { removeSearchCriteria } from "../../redux/features/searchSlice";

const headCells = tableHeaders["RULES"];

function RulesConfig() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0)
  const [rows, setRows] = useState([]);
  const [showEditDialog, setShowEditdialog] = useState(false)
  const [showDetailsDialog, setShowDetailsdialog] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const navigate = useNavigate()
  const [makeApiCall, loading] = useApi()
  const dispatch = useDispatch()
  const _selectedRule = useSelector(state => state.rules?.selectedRule)
  const { searchCriteria } = useSelector((state) => state.search);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = generateEmptyRows(page, rowsPerPage, rows);

  const visibleRows = useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, page, rowsPerPage, rows]
  );
  const { userDetails } = useSelector((state) => state?.user?.userInfo) || {};

  const handleNavigation = (row, type) => {
    dispatch(setSelectedRule(row))
    navigate(routesConfig.RULES_CONFIGURATION, { state: { id: row.ruleId, type } });
  };

  const fetchRules = async (payload=null, limit=5, offset=0) => {
    const res = await makeApiCall(getRules, limit, offset);
    if (res) {
      const {rules, totalRecords} = res?.data
      setTotalRecords(totalRecords)
      setRows(rules);
    } else {
      toastInstance("error", "Something went wrong!");
    }
  };

  useEffect(()=>{
    fetchRules()
    return () => {
      dispatch(removeSearchCriteria())
    }
  },[])

  const handleDelete = async (row) => {
    dispatch(setSelectedRule(row))
    setShowConfirm(true)
  };

  const confirmDelete = async () => {
    const res = await makeApiCall(deleteRule, _selectedRule?.ruleId);
    if (res.status === 200) {
      toastInstance("success", "Rule successfully deleted!");
      fetchRules();
    } else {
      toastInstance("error", "Something went wrong!");
    }
    setShowConfirm(false);
  };

  const searchRulesData = async (
    searchPayload,
    pageSize = 5,
    startIndex = 0
  ) => {
    const payload = searchPayload
      ? { ...searchPayload, pageSize, startIndex }
      : {
          ...searchCriteria,
          pageSize,
          startIndex,
        };

    const res = await makeApiCall(searchRules, payload);
    if (res) {
      const {rules, totalRecords} = res?.data
      setTotalRecords(totalRecords)
      setRows(rules);
    }
  };

  return (
    <>
      {loading && <Spinner />}
      {showDetailsDialog && (
        <RuleDetails handleClose={() => setShowDetailsdialog(false)} />
      )}
      {showEditDialog && (
        <RuleDetails type="edit" handleClose={() => setShowEditdialog(false)} />
      )}
      {showConfirm && (
        <Confirmation
          handleClose={() => setShowConfirm(false)}
          handleConfirm={confirmDelete}
        />
      )}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableToolbar
            title={"Rules"}
            component={"RISK_RULES"}
            fetchData={searchRulesData}
            fetchAllData={fetchRules}
            compoentToRender={
              userDetails?.role.includes("Admin")  && <AddNewRuleButton />
            }
          />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="Rules">
              <TableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length}
                headCells={headCells}
              />
              <TableBody>
                {visibleRows.length> 0 && visibleRows.map((row) => (
                  <TableRow hover key={row?.ruleId} sx={{ cursor: "pointer" }}>
                    <TableCell align="left">{row?.ruleId}</TableCell>
                    <TableCell align="left">{row?.ruleName}</TableCell>
                    <TableCell align="left">{row?.mode}</TableCell>
                    <TableCell align="left">{row?.score}</TableCell>
                    <TableCell align="left">{row?.status}</TableCell>
                    <TableCell align="left">
                      <StyledTableActionCell>
                        {(userDetails?.role.includes("Admin")  ||
                          userDetails?.role === "Editor") && (
                          <BorderColorOutlinedIcon
                            sx={{ color: "#3965FF" }}
                            onClick={() => handleNavigation(row, "edit")}
                          />
                        )}
                        {userDetails?.role.includes("Admin")  && (
                          <DeleteOutlinedIcon
                            sx={{ color: "#BD041A" }}
                            onClick={() => handleDelete(row)}
                          />
                        )}
                        <StyledButton onClick={() => handleNavigation(row)}>
                          Details
                        </StyledButton>
                      </StyledTableActionCell>
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalRecords}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            fetchData={fetchRules}
            searchData={searchRulesData}
          />
        </Paper>
      </Box>
    </>
  );
}

export default RulesConfig;
