import React, { useState } from "react";
import {
  StyledHeaderMenu,
  StyledNavContainer,
  StyledCompanyTitle,
} from "./styles";
import IconButton from "@mui/material/IconButton";
import SVGLoader from "../svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import AccountMenu from "../account-menu";
import { useLocation, useNavigate } from "react-router-dom";
import routesConfig from "../../constants/routeConfig";
import { useDispatch, useSelector } from "react-redux";
import { removeActiveMenu } from "../../redux/features/menuSlice";
import Tooltip from "@mui/material/Tooltip";
import { setCompanyName } from "../../redux/features/companySlice";

function Header() {
  const [openedItem, setOpenedItem] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyInfo = useSelector((state) => state.company);
  const location = useLocation();

  const showDownloads =
    location.pathname === routesConfig.SUPER_ADMIN_CONSOLE ? false : true;

  const handleItemClick = (e, item) => {
    setOpenedItem(item);
    setAnchorEl(e.currentTarget);
  };

  const handleDownloadsClick = () => {
    dispatch(removeActiveMenu());
    navigate(routesConfig.DOWNLOADS);
  };

  const changeCompany = () => {
    dispatch(setCompanyName(""));
    navigate(routesConfig.SUPER_ADMIN_CONSOLE);
  };

  return (
    <>
      <StyledNavContainer>
        <StyledCompanyTitle>
          <h1>{companyInfo.name}</h1>
          {companyInfo.name && (
            <Tooltip title={"Change company"} arrow placement="bottom">
              <IconButton onClick={changeCompany}>
                <SVGLoader icon={iconLibrary.CHANGE} color={"#05004e"} />
              </IconButton>
            </Tooltip>
          )}
        </StyledCompanyTitle>
        <StyledHeaderMenu>
          {showDownloads && (
            <Tooltip title={"Downloads"} arrow placement="bottom">
              <IconButton onClick={handleDownloadsClick}>
                <SVGLoader icon={iconLibrary.DOWNLOADS} />
              </IconButton>
            </Tooltip>
          )}
          {/* <IconButton>
            <SVGLoader icon={iconLibrary.THEME_MOON} />
          </IconButton>
          <IconButton>
            <SVGLoader icon={iconLibrary.INFO_ICON} />
          </IconButton> */}
          <Tooltip title={"Account"} arrow placement="bottom">
            <IconButton onClick={(e) => handleItemClick(e, "account")}>
              <SVGLoader icon={iconLibrary.CUSTOMER_ICON} />
            </IconButton>
          </Tooltip>
        </StyledHeaderMenu>
      </StyledNavContainer>
      {openedItem === "account" && (
        <AccountMenu
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
        />
      )}
    </>
  );
}

export default Header;
