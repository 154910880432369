import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../features/userSlice'
import rulesReducer from '../features/rulesSlice'
import searchReducer from '../features/searchSlice'
import menuReducer from '../features/menuSlice'
import firldTypereducer from '../features/fieldTypeSlice'
import companyReducer from '../features/companySlice'

const store = configureStore({
  reducer: {
    user: userReducer,
    company: companyReducer,
    rules: rulesReducer,
    search: searchReducer,
    menu: menuReducer,
    fieldTypes: firldTypereducer
  },
})

export default store