export const theme = {
  dark: {
    primary: "#2B3674",
    secondary: "#F0F5FF",
    highlight: "#4318FF",
    info: "#3965FF",
    grey: "#A3AED0",
    white: "#FFFFFF",
    positive: "#3965FF",
    negative: "#BD041A",
  },
  light: {
    primary: "#2B3674",
    secondary: "#F0F5FF",
    highlight: "#4318FF",
    info: "#3965FF",
    grey: "#A3AED0",
    white: "#FFFFFF",
    positive: "#3965FF",
    negative: "#BD041A",
  },
};
