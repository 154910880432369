import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableToolbar from "../../components/data-table/TableToolbar";
import Pagination from "../../components/data-table/Pagination";
import TableHead from "../../components/data-table/TableHeader";
import {
  getComparator,
  stableSort,
} from "../../components/data-table/helper-functions";
import { tableHeaders } from "../../constants/tableConfig";
import Spinner from "../../components/spinner";
import useApi from "../../hooks/useApi";
import { getAnalytics } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import DataLoadError from "../../components/load-error-warning/DashboardLoadError";
import { removeSearchCriteria } from "../../redux/features/searchSlice";

const headCells = tableHeaders["CUSTOMER_ANALYTICS"];

export default function CustomerAnalytics() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState([]);
  const [makeApiCall, loading] = useApi();
  const { searchCriteria } = useSelector((state) => state.search);
  const dispatch = useDispatch();

  const fetchAnalyticsData = async (
    filterPayload,
    pageSize = 5,
    startIndex = 0
  ) => {
    const payload = { ...filterPayload, pageSize, startIndex } || {
      ...searchCriteria,
      pageSize,
      startIndex,
    };
    const res = await makeApiCall(getAnalytics, payload);
    if (res) {
      setRows(res.data.listOfAnalytics);
      setTotalRecords(res.data.totalRecords);
    } else {
      return;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(removeSearchCriteria());
    };
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Box sx={{ width: "100%" }}>
      {loading && <Spinner />}
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableToolbar
          title={"Customer Analytics"}
          fetchData={fetchAnalyticsData}
          component={"CUSTOMER_ANALYTICS"}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
              headCells={headCells}
            />
            <TableBody>
              {visibleRows.length> 0 && visibleRows.map((row) => {
                return (
                  <TableRow
                    hover
                    key={row.transactionId}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell align="left">{row.deviceId}</TableCell>
                    <TableCell align="left">
                      {row.numberOfTransactions}
                    </TableCell>
                    <TableCell align="left">{row.numberOfUniqueDays}</TableCell>
                    <TableCell align="left">
                      {row.favoriteDevice ? "Yes" : "No"}
                    </TableCell>
                    <TableCell align="left">{row.locations}</TableCell>
                    <TableCell align="left">{row.favouriteLocations}</TableCell>
                    <TableCell align="left">
                      {row.favouriteTimeOfTheDay}
                    </TableCell>
                    <TableCell align="left">{row.favouriteISP}</TableCell>
                    <TableCell align="left">
                      {row.averageTransactions}
                    </TableCell>
                    <TableCell align="left">{row.deviceType}</TableCell>
                    <TableCell align="left">{row.alerts}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {totalRecords === 0 && (
            <DataLoadError
              message={
                "No data available! Please search using any valid Email ID or Phone number!!!"
              }
            />
          )}
        </TableContainer>
        {totalRecords > 0 && (
          <Pagination
            count={totalRecords}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            fetchData={fetchAnalyticsData}
            searchData={fetchAnalyticsData}
          />
        )}
      </Paper>
    </Box>
  );
}
