import React, { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { downloadReport, getReportStats } from "../../services";
import { StyledDownloadsTable } from "./styles";
import { formatTimeStamp } from "../../utils/commonUtils";
import { StyledBox } from "../../common-styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DataLoadError from "../../components/load-error-warning/DashboardLoadError";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function DownloadsTable(props) {
  const { data } = props;
  const [makeApiCall, loading] = useApi();
  const handleDownloadReport = async (reportName) => {
    const res = await makeApiCall(downloadReport, reportName);
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${reportName}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  return (
    <>
      <StyledDownloadsTable>
        <thead>
          <tr>
            <th>Generated On</th>
            <th>Report Name</th>
            <th>Status</th>
            <th>Generated By</th>
          </tr>
        </thead>
        {data?.length > 0 && (
          <tbody>
            {data.map((i) => (
              <tr>
                <td>{formatTimeStamp(i.updatedOn)}</td>
                <td onClick={() => handleDownloadReport(i.reportName)}>
                  <a>{i.reportName}</a>
                </td>
                <td>{i.status}</td>
                <td>{i.generatedBy}</td>
              </tr>
            ))}
          </tbody>
        )}
      </StyledDownloadsTable>
      {!data?.length && <DataLoadError message={"No data available!!"} />}
    </>
  );
}

function Downloads() {
  const [downloadsData, setDownloadsData] = useState(null);
  const [uploadsData, setUploadsData] = useState(null);
  const [value, setValue] = useState(0);
  const [makeApiCall, loading] = useApi();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchData = async () => {
    const res = await makeApiCall(getReportStats);
    const filteredData = res?.data
      .sort((a, b) => b.updatedOn - a.updatedOn)
      .reduce(
        (a, c) => {
          if (c.reportType === "ReportDownload") {
            a.downloadsData.push(c);
          } else {
            a.uploadsData.push(c);
          }
          return a;
        },
        { downloadsData: [], uploadsData: [] }
      );
    setDownloadsData(filteredData?.downloadsData || []);
    setUploadsData(filteredData?.uploadsData || []);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <StyledBox>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Downloads" {...a11yProps(0)} />
          <Tab label="Uploads" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <DownloadsTable data={downloadsData} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <DownloadsTable data={uploadsData} />
      </CustomTabPanel>
    </StyledBox>
  );
}

export default Downloads;
