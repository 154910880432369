import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "../form-elements/text-field";
import {
  StyledDialogTitle,
  StyledDialogActions,
  StyledDialogContent,
} from "./styles";
import SVGLoader from "../svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import { toastInstance } from "../../utils/toastInstance";
import useApi from "../../hooks/useApi";
import ColorSelector from "../form-elements/color-selector";
import { useForm } from "react-hook-form";
import { createRisk, updateRisk } from "../../services";
import Spinner from "../spinner";
import { getOnlyDirtyFields } from "../../utils/commonUtils";

function AddorEditRiskDialog(props) {
  const { handleClose, isEdit, updateList, riskData } = props;
  const [makeApiCall, loading] = useApi();
  const form = useForm({
    defaultValues: {
      minScore: riskData?.minScore || "",
      maxScore: riskData?.maxScore || "",
      riskName: riskData?.riskName || "",
      tagColor: riskData?.tagColor || "",
    },
  });
  const { register, handleSubmit, formState, watch, getValues  } = form;
  const { errors, dirtyFields } = formState;

  const onSubmit = async (data) => {
    if (isEdit) {
      const updatedValues = getOnlyDirtyFields(dirtyFields, data)
      const res = updatedValues && await makeApiCall(updateRisk, riskData.riskId, updatedValues);
      if (res?.status === 200) {
        toastInstance("success", "Risk updated successfully!");
        updateList();
        handleClose();
      }
    } else {
      const res = await makeApiCall(createRisk, data);
      if (res?.status === 201) {
        toastInstance("success", "Risk created successfully!");
        updateList();
        handleClose();
      }
    }
  };
  const title = isEdit ? "Edit Risk Details" : "Add New Risk Details";
  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="lg">
      {loading && <Spinner />}
      <StyledDialogTitle>
        <section className="dialog-header-content">
          <span>{title}</span>
          <div onClick={handleClose}>
            <SVGLoader icon={iconLibrary.CANCEL_ICON} />
          </div>
        </section>
      </StyledDialogTitle>
      <StyledDialogContent>
        <TextField
          label="Minimum Risk Score"
          name="minScore"
          type="number"
          id="minScore"
          validation={register("minScore", {
            valueAsNumber: true,
            required: " Minimum risk score is required",
          })}
          errorMessage={errors.minScore?.message}
        />
        <TextField
          label="Maximum Risk Score"
          name="maxScore"
          type="number"
          id="maxScore"
          validation={register("maxScore", {
            valueAsNumber: true,
            validate: (value) => {
              return (
                parseInt(value, 10) > parseInt(getValues("minScore"), 10) ||
                "Maximum score should be greater than minimum score"
              );
            },
            required: "Maximum risk score is required",
          })}
          errorMessage={errors.maxScore?.message}
        />
        <TextField
          label="Risk Name"
          name="riskName"
          id="riskName"
          validation={register("riskName", {
            required: "Risk name is required",
          })}
          errorMessage={errors.riskName?.message}
        />
        <ColorSelector
          label="Tag Color"
          id="tagColor"
          name="tagColor"
          color={watch("tagColor")}
          validation={register("tagColor", {
            required: "Tag colour is required",
          })}
          errorMessage={errors.tagColor?.message}
        />
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Back
        </Button>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          Apply
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
}

export default AddorEditRiskDialog;
