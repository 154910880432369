import React, { useState } from "react";
import { StyledIconButtonWrapper } from "../../common-styles";
import SVGLoader from "../../components/svgLoader";
import iconLibrary from "../../constants/iconLibrary";
import AddUserDialog from "../../components/dialog/AddUser";

const AddNewUserButton = (props) => {
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const openAddUserDialog = () => {
    setShowAddUserDialog(true);
  };

  const closeAddUserDialog = () => {
    setShowAddUserDialog(false);
  };
  return (
    <>
      {showAddUserDialog && (
        <AddUserDialog
          handleClose={closeAddUserDialog}
          updateList={props.updateList}
        />
      )}
      <StyledIconButtonWrapper onClick={openAddUserDialog}>
        <SVGLoader icon={iconLibrary.ADD_USER_ICON} />
      </StyledIconButtonWrapper>
    </>
  );
};

export default AddNewUserButton;
