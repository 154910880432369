import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const serachSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchCriteria: (state, action) => {
      state.searchCriteria = action.payload;
    },
    removeSearchCriteria: (state) => {
      state.searchCriteria = null;
    },
  },
});

export const { setSearchCriteria, removeSearchCriteria } = serachSlice.actions;

export default serachSlice.reducer;
