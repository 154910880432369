import React from "react";
import Tooltip from '@mui/material/Tooltip';
import { StyledTooltipLink } from "../../common-styles";

function ViewmoreToolTip({ fieldValues }) {
  const displayData = fieldValues.slice(0, 2).join(",");
  const toolTipData = "Click edit to view all the data";
  const tooltipLinkText = `${fieldValues.length - 2} more...`;
  return (
    <>
      {displayData}
      <br />
      <Tooltip title={toolTipData} arrow>
        <StyledTooltipLink>{tooltipLinkText}</StyledTooltipLink>
      </Tooltip>
    </>
  );
}

export default ViewmoreToolTip;
