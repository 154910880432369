import styled from "styled-components"

export const StyledTooltip = styled.section`
    background-color: #fff;
    font-family: DM Sans;
    padding: 5%;
    width: 150px;
    border: 1px solid #111;
    .label{
        color: #00E096;
        font-size: 14px;
    }
`
export const StyledDashbardErrorWrapper = styled.section`
    display: flex;
    flex-direction: column;
    height: 250px;
    justify-content: center;
    align-items: center;
    font-family: DM Sans;
    svg{
        height: 48px;
        width: 48px;
        margin-bottom: 10px;
    }
`