import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DataLoadError from "../load-error-warning/DashboardLoadError";

function DeviceLocationTable(props) {
  const { data, isFetching } = props;
  const processedData = data?.sort((a,b)=>b.countOfDevices - a.countOfDevices).slice(0,5)
  return (
    <TableContainer component={Paper}>
      <Table aria-label="Device location table">
        <TableHead>
          <TableRow>
            <TableCell>Country</TableCell>
            <TableCell align="right">Count of devices</TableCell>
            <TableCell align="right">No of transactions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {processedData?.length > 0 &&
            processedData.map((row) => (
              <TableRow
                key={row.country}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.country}
                </TableCell>
                <TableCell align="right">{row.countOfDevices}</TableCell>
                <TableCell align="right">{row.noOfTransactions}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {!isFetching && (data?.length === 0 || !data) && <DataLoadError />}
    </TableContainer>
  );
}

export default DeviceLocationTable;
