import iconLibrary from "./iconLibrary";
import routesConfig from "./routeConfig";

const menuList = [
  {
    id: 0,
    section: "Dashboard",
    icon: iconLibrary.DASHBOARD_ICON,
    routePath: routesConfig.DASHBOARD,
  },
  {
    id: 1,
    section: "Transactions",
    icon: iconLibrary.TRANSACTION_ICON,
    routePath: routesConfig.TRANSACTIONS,
  },
  {
    id: 2,
    section: "Risk Rules",
    icon: iconLibrary.RULES_ICON,
    routePath: routesConfig.RULES,
    subPaths: [routesConfig.RULES_CONFIGURATION]
  },
  {
    id: 3,
    section: "Customer Analytics",
    icon: iconLibrary.ANALYTICS_ICON,
    routePath: routesConfig.CUSTOMER_ANALYTICS,
  },
  {
    id: 4,
    section: "Configurations",
    icon: iconLibrary.CONFIGURATIONS_ICON,
    routePath: routesConfig.CONFIGURATIONS,
  },
  {
    id: 5,
    section: "User Management",
    icon: iconLibrary.GROUP_ICON,
    routePath: routesConfig.USER_MANAGEMENT,
  },
  {
    id: 6,
    section: "Reports",
    icon: iconLibrary.REPORT_ICON,
    routePath: routesConfig.REPORTS,
  },
];

export default menuList;
